import { Divider, Grid } from '@material-ui/core';
import React, { ReactNode, useState, useEffect, useRef, useCallback } from 'react';
import { ListModuloFilho } from './components/list-modulo-filho/list-modulo-filho';
import { MenuPrincipalPerfil } from './components/menu-principal-perfil/menu-principal-perfil';
import { MenuPrincipalTop } from './components/menu-principal-top/menu-principal-top';
import { useStyles } from './menu-principal-styles';
import classNames from 'classnames';
import { useMenuPrincipal } from '../../../../../services/providers/menu-principal-provider';
import { useThemeQueries } from '../../../../theme';
import { useGlobalStates } from '../../../../../services/providers/global-states-provider/global-states-provider';
import { useGlobalStateTools } from '../../../../../services/use-cases/global-states/global-state-tools';
import { useGetMenu } from '../../../../../data/app';
import { MenuModel } from '../../../../../model/app/master';
import { EnumMenu } from '../../../../../model/enums/enum-menu';

export const MenuPrincipal = () => {
  const { alterarModelo, fecharMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const { menuGlobalState } = useGlobalStates();
  const { getValue } = useGlobalStateTools();

  const menuState = getValue(menuGlobalState!);
  const modeloAtual = menuState.modelo;
  const situacaoAtual = menuState.situacao;

  //CORRECAO DE MOBILE PARA O MENU
  const CorrecaoModeloAtual = isMobile ? 'Completo' : modeloAtual;

  const classes = useStyles();
  const { getMenu } = useGetMenu();
  const [listaModulos, setListaModulos] = useState(null as ReactNode);
  const [modulosRetornados, setModulosRetornados] = useState([] as MenuModel[]);

  useEffect(() => {
    if (isMobile && modeloAtual !== 'Completo') alterarModelo('Completo');
  }, [alterarModelo, isMobile, modeloAtual]);

  const refDrawer = useRef<HTMLDivElement>(null);

  const pinClicked = useCallback(() => {
    if (isMobile) {
      fecharMenu();
      return;
    }

    if (modeloAtual === 'Completo') {
      alterarModelo('Mini');
    } else {
      alterarModelo('Completo');
    }
  }, [modeloAtual, isMobile, fecharMenu, alterarModelo]);

  useEffect(() => {
    setListaModulos(
      modulosRetornados
        .map((item) => (
          <ListModuloFilho
            key={item.codigo}
            modeloAtual={CorrecaoModeloAtual}
            codigoModulo={item.codigo}
            nomeModulo={item.nome}
            isFavorite={false}
            rota={item.rota}
          />
        )),
    );
  }, [CorrecaoModeloAtual, modulosRetornados]);

  const leftAtual = !isMobile
    ? 0
    : situacaoAtual === 'Aberto'
      ? 0
      : modeloAtual === 'Completo'
        ? -350
        : -75;

  const itensMenu = React.useMemo(() => {
    return (
      <Grid className={classes.drawerOverflowMenu}>
        <Divider classes={{ root: classes.divider }} />
        {listaModulos}
      </Grid>
    );
  }, [classes.divider, classes.drawerOverflowMenu, listaModulos]);

  useEffect(() => {
    const ret = getMenu(false, undefined);
    setModulosRetornados(ret);
  }, [getMenu]);

  const component = React.useMemo(() => {
    return (
      <>
        {isMobile && (
          <div
            className={classNames(
              classes.drawerBackground,
              isMobile && situacaoAtual === 'Aberto'
                ? classes.drawerBackgroundAberto
                : undefined,
            )}
            onClick={fecharMenu}
          ></div>
        )}
        <div
          className={classNames(
            classes.drawer,
            isMobile ? classes.drawerMobile : undefined,
            situacaoAtual === 'Aberto'
              ? isMobile
                ? classes.openDrawerMobile
                : classes.openDrawer
              : undefined,
            modeloAtual === 'Mini' ? classes.drawerReduzido : undefined,
          )}
          style={{ left: leftAtual }}
        >
          {
            <Grid ref={refDrawer} className={classes.drawerContainer}>
              <Grid className={classes.drawerTop}>
                <MenuPrincipalTop
                  isMobile={isMobile}
                  modeloAtual={CorrecaoModeloAtual}
                  pinClicked={pinClicked}
                />
                <MenuPrincipalPerfil
                  isMobile={isMobile}
                  modeloAtual={CorrecaoModeloAtual}
                />
              </Grid>
              {itensMenu}
              <Grid className={classes.drawerBottom}>
                <Divider classes={{ root: classes.divider }} />
                <ListModuloFilho
                  key={EnumMenu.MASTER_SAIR}
                  isFavorite={false}
                  modeloAtual={CorrecaoModeloAtual}
                  codigoModulo={EnumMenu.MASTER_SAIR}
                  nomeModulo="Sair"
                  rota="/logout"
                />
              </Grid>
            </Grid>
          }
        </div>
      </>
    );
  }, [CorrecaoModeloAtual, classes.divider, classes.drawer, classes.drawerBackground, classes.drawerBackgroundAberto, classes.drawerBottom, classes.drawerContainer, classes.drawerMobile, classes.drawerReduzido, classes.drawerTop, classes.openDrawer, classes.openDrawerMobile, fecharMenu, isMobile, itensMenu, leftAtual, modeloAtual, pinClicked, situacaoAtual]);

  return component;
};
