import { makeStyles } from "@material-ui/core"


export const useStyles = makeStyles((theme) => ({
    loginCard: {
        boxShadow: '0px 52.9835px 137.757px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '52px 41px',
        borderRadius: '26.4918px',
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            padding: '52px 21px'
        },
        '& h4': {
            fontWeight: 500
        }
    },
    divider: {
        background: theme.palette.grey[400]
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    rootPadding: {
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    containerForms: {
        width: '100%'
    }
}))