import { useStyles } from "./response-page-styles";
import { Button, Grid, Grow, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { ResponseHeader } from "./components/response-header.tsx/response-header.tsx";
import confirmado from "../../../components/icons/icon-img/confirmado.svg"
import { useResponseContext } from "../../../../services";
import { ConfirmarIcon } from "../../../components/icons/confirmar-icon";
import { ResponseList } from "./components/response-list.tsx/response-list";
import Error from '../../../../assets/img/erro.svg';

type Location = {
    backUrl: string,
    teste: string
}

export const ResponsePage = () => {
    const classes = useStyles();
    const { message } = useResponseContext();
    const history = useHistory();
    const { backUrl } = history.location.state as Location

    return (
        <>
            <Grid className={classes.root}>
                <Grid className={classes.header}>
                    <ResponseHeader />
                </Grid>
                {message === 'Entrega já registrada!' ?
                    <>
                        <div className={classes.rootJaRegistrada}>
                            <div className={classes.content}>
                                <Grow in={true}>
                                    <img style={{ width: '128px' }} src={Error} alt="Imagem Resposta" />
                                </Grow>
                            </div>
                            <Grow in={true}>
                                <Typography className={classes.text}>
                                    {message}
                                </Typography>
                            </Grow>
                            <Grid container className={classes.footerBox}>
                                <Grid item xs={9} md={6}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => history.push(backUrl ?? '/scan/camera')}
                                        fullWidth
                                    >
                                        <ConfirmarIcon tipo="BUTTON" />
                                        Fechar
                                    </Button>
                                </Grid>
                        </Grid>
                    </div>
            </>
            :
            <>
                <div className={classes.content}>
                    <Grow in={true}>
                        <img style={{ width: '128px' }} src={confirmado} alt="Imagem Resposta" />
                    </Grow>
                </div>
                <Grow in={true}>
                    <Typography className={classes.text}>
                        {message}
                    </Typography>
                </Grow>
                <Grid item xs={12} md={12} className={classes.list}>
                    <ResponseList />
                    <Grid container className={classes.footerBox}>
                        <Grid item xs={12} md={12}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={() => history.push(backUrl ?? '/leitor')}
                                fullWidth
                            >
                                <ConfirmarIcon tipo="BUTTON" />
                                Continuar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </Grid >
        </>
    )
}