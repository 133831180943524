import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupDocumento } from '../../form-validations/yup/documento';
import { useValidationYupSenha, useValidationYupSenhaConfirmacao } from '../../form-validations';
import { isEmpty } from 'lodash';

export const useFormCadastroValidation = () => {

  const { documentoYup } = useValidationYupDocumento(true)
  const { senhaYup } = useValidationYupSenha()
  const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

  const formCadastroValidation = useMemo(() => {
    return Yup.object().shape({
      nomeTerminal: Yup.string().required('O nome do terminal é obrigatório').typeError('O nome do terminal é obrigatório'),
      cnpj: documentoYup('CNPJ').required('O CNPJ é obrigatório.'),
      senha: senhaYup(true),
      confirmarSenha: !isEmpty(Yup.ref('senha')) ? senhaConfirmacaoYup('senha') : Yup.string().notRequired()
    });
  }, [documentoYup, senhaConfirmacaoYup, senhaYup])

  return {
    formCadastroValidation,
  }
}

