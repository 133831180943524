import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const VoltarSairIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M8.72144 16.086C11.2622 18.6001 13.6158 20.9536 15.9693 23.2804C16.6914 24.0025 17.4403 24.7247 18.1624 25.4735C18.6438 25.9817 18.7508 26.5433 18.2159 27.0515C17.7077 27.5329 17.1996 27.3991 16.7182 26.9177C12.8669 23.0665 9.04237 19.2419 5.1911 15.4174C4.78993 15.0162 4.9504 14.7755 5.27134 14.4813C9.06912 10.7103 12.8669 6.91253 16.6379 3.1415C17.1461 2.63334 17.7077 2.49962 18.2159 3.03452C18.724 3.56942 18.5368 4.13106 18.0554 4.61247C15.8356 6.8323 13.6425 9.02538 11.4227 11.2452C10.5936 12.0743 9.711 12.9034 8.69469 13.893C9.25633 13.9197 9.57727 13.9464 9.87147 13.9464C14.7925 13.9464 19.7136 13.9197 24.6347 13.9464C28.8069 13.9732 32.8721 14.5081 36.5897 16.5407C41.0561 19.0012 43.5433 22.8525 44.0782 27.8806C44.7736 34.2993 43.2491 40.2635 40.2805 45.9334C40.2002 46.0671 40.12 46.2276 40.013 46.3613C39.6653 46.8695 39.2107 47.2171 38.5955 46.8427C37.9804 46.4683 37.9002 45.9334 38.2746 45.2915C39.6118 42.9647 40.5746 40.5042 41.2165 37.9099C42.1259 34.1656 42.634 30.3946 41.7782 26.5701C40.6549 21.649 37.4455 18.707 32.8186 17.1558C30.3581 16.3267 27.8441 16.0058 25.2498 16.0058C19.9811 16.0058 14.739 16.0058 9.47029 16.0058C9.33657 16.0326 9.1761 16.0326 8.72144 16.086Z" />
      </DefaultIcon>
    </>
  );
};





