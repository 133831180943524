import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divider: {
        background: theme.palette.grey[300]
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        fontWeight: 700,
    },
}))