import { useCallback } from 'react';
import { EnumMenu } from '../../../../model/enums/enum-menu';
import { HistoricoIcon } from '../historico-icon';
import { LeitorIcon } from '../leitor-icon';
import { LogoutIcon } from '../logout-icon';
import { IconsHelperProps } from './icons-helper-props';
import { ConfiguracoesIcon } from '../configuracoes-icon';

export const IconsHelper = (props: IconsHelperProps) => {
  const selectIcon = useCallback(() => {

    switch (props.codigoModulo) {

      case EnumMenu.LEITOR:
        return <LeitorIcon tipo='BUTTON' />;

      case EnumMenu.HISTORICO:
        return <HistoricoIcon tipo='BUTTON' />;

      case EnumMenu.CONFIGURACOES:
        return <ConfiguracoesIcon tipo='BUTTON' />;

      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;

    }
  }, [props]);

  return <>{selectIcon()}</>;
};
