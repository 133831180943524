import { makeStyles } from '@material-ui/core';

interface ScannerInicialStylesProps {
    isMobile: boolean;
}

export const useStylesInicial = makeStyles((theme) => ({
    containerScann: (props: ScannerInicialStylesProps) => ({
        minWidth: props.isMobile ? '90%' : '42%',
        borderRadius: '8px',
        background: 'black',
        display: 'flex',
        flexDirection: 'column-reverse',
        "& .drawingBuffer": {
            display: 'none'
        },

        '& video': {
            minWidth: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px'
        }
    }),
    containerPreviewInfo: {
        width: '100%',
        height: '60px',
        background: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px'
    },
    textPreview: {
        color: '#fff',
        fontSize: '16px',
        lineHeight: '31px',
        fontWeight: 600
    },
    semCameraIcon: {
        '& svg': {
            width: '70px',
            height: '70px',
        }
    }
}));

