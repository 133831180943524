import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useCallback } from 'react';
import { useBaixasToken } from '../../../services/use-cases/baixas-token';
import { useToastSaurus } from '../../../services/use-cases/toast-saurus';
import { useSessaoAtual } from '../../../services/providers/sessao-atual-provider/sessao-atual-provider';
import { TicketStorageKeys, useTicketStorage } from '../../../services/use-cases/ticket-storage';

export const ExpiredTokenRoute: React.FC<RouteProps> = ({
   children,
   ...otherProps
}) => {
   const { IsValidSession } = useSessaoAtual();
   const { isTokenExpired, decodeToken } = useBaixasToken();
   const { showToast } = useToastSaurus();
   const { delRegistro, getRegistro } = useTicketStorage();
   const token = getRegistro(TicketStorageKeys.AuthToken)

   const retRoute = useCallback(() => {
      if (!IsValidSession()) {
         if (isTokenExpired(decodeToken(token))) {
            showToast(
               'error',
               `Sua sessão expirou. Realize o login novamente para continuar. (401) `,
            );
            delRegistro(TicketStorageKeys.AuthToken, false);
            return <Redirect to="/login" />;
         }
      }

      return !IsValidSession ? (
         <Redirect to="/login" />
      ) : (
         children
      );
   }, [IsValidSession, children, isTokenExpired, decodeToken, token, showToast, delRegistro]);
   return <Route {...otherProps}>{retRoute()}</Route>;
};
