import { useStyles } from './menu-principal-perfil-styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { MenuPrincipalPerfilProps } from './menu-principal-perfil-props';
import { TicketStorageKeys, useTicketStorage } from '../../../../../../../services/use-cases/ticket-storage';
import { UserDadosModel } from '../../../../../../../model/api/UserDados';

export const MenuPrincipalPerfil = (props: MenuPrincipalPerfilProps) => {
  const classes = useStyles();
  const { getRegistro } = useTicketStorage()

  const model = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel
  const component = React.useMemo(() => {

    return (
      <>
        <div
          className={classNames(
            classes.drawerResumoPerfilContainer,
            props.modeloAtual === 'Completo' && !props.isMobile
              ? classes.drawerPerfilCompletoNotMobile
              : undefined,
            props.modeloAtual === 'Mini' ? classes.drawerPerfilMini : undefined,
          )}
        >
          {props.modeloAtual === 'Completo' && (
            <div className="nomeContainer">
              <div className={classes.containerNomeEPlano}>
                <Typography className={classes.usuarioNome}>
                  {model.nomeTerminal}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
    [
      classes.containerNomeEPlano,
      classes.drawerPerfilCompletoNotMobile,
      classes.drawerPerfilMini,
      classes.drawerResumoPerfilContainer,
      classes.usuarioNome, model.nomeTerminal,
      props.isMobile,
      props.modeloAtual,
    ],
  );

  return component;
};
