import { useStyles } from "./scan-camera-page-styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useResponseContext } from "../../../../../../../../services";
import { useQrCode } from "../../../../../../../../data/api/baixas/qrcode/qrcode-service";
import { useSessaoAtual } from "../../../../../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { useToastSaurus } from "../../../../../../../../services/use-cases/toast-saurus";
import { QrCodeApiModel } from "../../../../../../../../model/api/QrCode/QrCodeApiModel";
import { stringNumeros } from "../../../../../../../../utils/string-numeros";
import { ProdModel } from "../../../../../../../../model/api/Prod";
import { CircularLoading } from "../../../../../../../components/utils/circular-loading";
import Scanner from "./scanner/scanner";
import { useCallback } from "react";
import { ToastScan } from "./toast-scan/toast-scan";
import { DefaultCard } from "../../../../../../../components/cards/components";
import classNames from "classnames";
import useEventListener from "@use-it/event-listener";
import { focusableElement } from "../../../../../../../../utils/focusable-element";
import { TicketStorageKeys, useTicketStorage } from "../../../../../../../../services/use-cases/ticket-storage";
import { UserDadosModel } from "../../../../../../../../model/api/UserDados";
import { EnumTpLeitor } from "../../../../../../../../model/enums/enum-tp-leitor";
import { useHistory } from "react-router";

export const ScanCamera = () => {

    //AUX
    const classes = useStyles();
    const { showToast, showToastPersonalizado } = useToastSaurus()
    const { getRegistro } = useTicketStorage();
    const history = useHistory();

    //PROVIDERS
    const { getData } = useSessaoAtual();
    const { setProd } = useResponseContext();

    //CHAMADA DA API
    const { ScanQrCode, carregando } = useQrCode();

    const usuarioAtual = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel;

    useEventListener('keydown', (event: any) => {
        if (
            !event.defaultPrevented &&
            !focusableElement(document.activeElement) &&
            usuarioAtual.tpLeitor === EnumTpLeitor.LeitorCameraTeclado
        ) {
            const ev = event as KeyboardEvent;
            if (!ev.key) return

            if (ev.key === 'Enter') {
                return;
            }

            history.push('/nova-leitura/fisico')
        }
    });

    const handleScan = useCallback(async (data: any) => {
        if (data) {
            const rplc = data.replaceAll(";", "/")
            const res = await ScanQrCode(new QrCodeApiModel(rplc, stringNumeros(String(getData()?.cnpj)), getData()?.nomeTerminal))
            try {
                if (res.erro) {
                    if (res.erro.title === 'Entrega já registrada!') {
                        const model = res.erro.body as ProdModel;
                        setProd(model)
                        showToastPersonalizado(
                            'confirm',
                            <ToastScan
                                erro={res.erro}
                                model={model}
                            />,
                            10000,
                        );
                        return;
                    }

                    throw res.erro
                }

                let model = res.resultado?.data as ProdModel;
                setProd(model)
                showToastPersonalizado(
                    'confirm',
                    <ToastScan
                        erro={res.erro}
                        model={model}
                    />,
                    10000,
                );

            } catch (e: any) {
                showToast('error', res.erro.message);
                return;
            }
        }
    }, [ScanQrCode, getData, setProd, showToast, showToastPersonalizado])

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <DefaultCard className={classes.defaultCard}>
                <Grid container className={classNames(classes.root)}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Leitor QRCode - Câmera
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.titleSub}>
                            Aproxime a camera no leitor para dar baixa na entrega
                        </Typography>
                        <Divider variant="fullWidth" className={classes.divider} />
                    </Grid>
                    <Grid item xs={12} className={classes.qrcode}>
                        <Scanner inicialOrSecundario={false} onScan={handleScan} />
                    </Grid>
                </Grid>
            </DefaultCard>
        </>
    )
}
