import { EnumTpLeitor } from "../../enums/enum-tp-leitor";

export class UserDadosModel {
    constructor(
        public nomeTerminal: string = '',
        public cnpj: string = '',
        public tpLeitor: EnumTpLeitor = EnumTpLeitor.LeitorCamera,
        public senha: string = '',
        public confirmarSenha: string = '',
    ) { }
}