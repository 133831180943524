import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupSenha } from '../../form-validations';

export const useFormConfirmacaoUsuarioValidation = () => {

    const { senhaSyncYup } = useValidationYupSenha()

  const formConfirmacaoUsuarioValidation = useMemo(() => {
    return Yup.object().shape({
        senha: senhaSyncYup(),
    });
  }, [senhaSyncYup])

  return {
    formConfirmacaoUsuarioValidation,
  }
}

