import { createStyles, makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) =>
    createStyles({
        text: {
            color: '#F68D31',
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '25px',
            fontSize: '28px',
            fontWeight: 600
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0 0 0'
        },
        footerBox: {
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '40px'
        },
        iten: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        lineText: {
            marginTop: '15px',
        },
        mainBox: {
            justifyContent: "center",
            display: 'flex',
            marginTop: '50px'
        },
        button: {
            backgroundColor: '#F68D31',
            color: 'white',
            fontWeight: 600,

            marginTop: '20px',
            borderRadius: '5.70314px',
            "&:hover": {
                backgroundColor: '#E6A973',
            }
        },
        textCategoria: {
            fontWeight: 600,
            color: '#5F6368',
            fontSize: '14px',
            // textAlign: 'end',
            "& span": {
                color: '#F68D31'
            },
        },
        titleObject: {
            fontWeight: 600,
            fontSize: 10,
            color: '#FF9F4A'
        },
        valueObject: {
            fontSize: '12px',
            fontWeight: 600,
            color: '#5F6368'
        },
        container: {
            maxWidth: 650,
            padding: 8
        },
        setor: {
            backgroundColor: '#FF9F4A',
            padding: '4px 10px',
            fontSize: '.8rem',
            fontWeight: 600,
            color: '#FFF',
            borderRadius: '5px',
        },
        divider: {
            width: '100%',
            height: '1px',
            marginTop: '8px',
            marginBottom: '50px',
            backgroundColor: '#E6E6E6',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: '1px',
                marginTop: '8px',
                marginBottom: '10px',
                backgroundColor: '#E6E6E6',
            }
        },
        list: {
            flex: '1 1 100%',
            overflow: 'auto',
            position: 'relative',
            overflowX: 'hidden',
            margin: '0 170px',
            [theme.breakpoints.down('xs')]: {
                flex: '1 1 100%',
                overflow: 'auto',
                position: 'relative',
                overflowX: 'hidden',
                margin: '0 28px',
            }
        },
        divider2: {
            width: '100%',
            height: '1px',
            margin: '10px 0',
            backgroundColor: '#696969',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: '12px 0',
                height: '1px',
                backgroundColor: '#696969',
            }
        },
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflowX: "hidden",
            backgroundColor: '#f4f4f4'
        },
        rootJaRegistrada: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
        },
        header: {
            flex: "0 1 auto"
        },
    })
) 