import { useCallback } from "react";
import { useApiBase } from '../base/api-baixas-base'
import { AuthModel } from '../../../../model/api/Auth/auth-model'

export const usePostIdentidadeAutenticar = () => {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticar = useCallback(
        (model : AuthModel) => 
             invocarApi({
                url: '/api/auth/integrar',
                method: 'POST',
                enviarTokenUsuario: false,

                data: model
             }),
        [invocarApi],
    )
    return {                
        ...outrasPropriedades,
        postIdentidadeAutenticar,
    }
}
