import { useCallback } from "react";
import { useApiBase } from '../base/api-baixas-base'
import { QrCodeApiModel } from '../../../../model/api/QrCode/QrCodeApiModel'

export const useQrCode = () => {
    const { invocarApi, ...props } = useApiBase();

    const ScanQrCode = useCallback(
        (model: QrCodeApiModel) => {
            return invocarApi({
                url: '/api/baixa/registrar-entrega',
                method: 'POST',
                enviarTokenUsuario: true,
                data: model
            })
        },
        [invocarApi],
    )
    return {
        ScanQrCode,
        ...props
    }
}
