import { makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";

interface ToastScanStylesProps {
    erro: any;
}

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: '8px',
        maxWidth: '500px'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    status: (props: ToastScanStylesProps) => ({
        paddingRight: '8px',
        '& svg': {
            height: '80x',
            width: '80px',
            fill: isEmpty(props.erro) ? theme.palette.success.main : theme.palette.error.main
        }
    }),
    containerItens: {
        whiteSpace: 'normal'
    },
    textItens: {
        color: theme.palette.text.primary,
        display: 'flex',
    },
    conteudoProd: {
        paddingLeft: '8px',
    },
}))