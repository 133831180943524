import { Box, Button, Card, Divider, Grid, Typography } from "@material-ui/core"
import { useStyles } from "./login-page-styles"
import { useCallback, useEffect, useRef, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useSessaoAtual } from "../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { useHistory } from "react-router";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { makeUtilClasses } from "../../../theme";
import { LoginIcon } from "../../../components/icons/login-icon";
import { FormLogin } from "../../../components/form/form-login/form-login";
import { DefaultFormRefs } from "../../../components/form/utils";
import { DadosLoginModel } from "../../../../model/api/login/dados-login-model";
import { CircularLoading } from "../../../components/utils/circular-loading";
import { useToastSaurus } from "../../../../services/use-cases/toast-saurus";
import { ConfiguracoesIcon } from "../../../components/icons/configuracoes-icon";
import { DialogConfirmacaoUsuario } from "../../../components/dialog/dialog-confirmacao-usuario/dialog-confirmacao-usuario";

export const LoginPage = () => {

    //AUX
    const { showToast } = useToastSaurus()
    const classes = useStyles();
    const utilClasses = makeUtilClasses();
    const history = useHistory();

    //PROVIDERS
    const { Login, FirstAccess, getData: data, carregando } = useSessaoAtual();

    //STATES E REFS
    const [bloqueado, setBloqueado] = useState<boolean>(false);
    const [abrirDialog, setAbrirDialog] = useState<boolean>(false);
    const loginRefs = useRef<DefaultFormRefs<DadosLoginModel>>(null);

    useEffect(() => {
        if (loginRefs) loginRefs.current?.fillForm(
            new DadosLoginModel(
                data()?.nomeTerminal || '',
                ''
            ));
    }, [data]);

    const efetuarLogin = useCallback(async (model: DadosLoginModel) => {
        try {

            await Login(model.nomeTerminal, model.senha);

            setBloqueado(true);

            return history.push('/');

        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [Login, history, showToast]);

    useEffect(() => {
        if (FirstAccess()) {
            history.push('/primeiro-acesso')
        }
    }, [FirstAccess, history])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={`${utilClasses.contentCenter} ${classes.rootPadding}`}>
                <Grid container className={utilClasses.rootContainer}>
                    {carregando || bloqueado ? (
                        <CircularLoading tipo="FULLSIZED" />
                    ) : null}
                    <Grid item xs={12}>
                        <Card className={classes.loginCard}>
                            <Grid container direction="column">
                                <Grid item xs={12} className={classes.textContainer}>
                                    <Typography align="left" variant="h4" color="primary">
                                        Login
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        Por favor, entre digitando as informações abaixo para poder continuar.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.containerForms}>
                                    <FormLogin
                                        showLoading={false}
                                        ref={loginRefs}
                                        loading={carregando}
                                        onSubmit={(model) => {
                                            efetuarLogin(model);
                                        }}
                                    />
                                    <Box mt={7}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Divider 
                                                    variant="fullWidth"
                                                    className={classes.divider}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => {
                                                        setAbrirDialog(true)
                                                    }}
                                                >
                                                    <ConfiguracoesIcon tipo="BUTTON" />
                                                    Configurações
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                    fullWidth={true}
                                                    disabled={carregando || bloqueado}
                                                    onClick={() => {
                                                        loginRefs.current?.submitForm();
                                                    }}
                                                >
                                                    <LoginIcon tipo="BUTTON_PRIMARY" />
                                                    Acessar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            {abrirDialog && (
                <DialogConfirmacaoUsuario
                    aberto={abrirDialog}
                    setAbrirDialog={setAbrirDialog}
                />
            )}
        </>
    )
}