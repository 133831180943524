import { makeStyles } from "@material-ui/core"

interface StylesProps {
    open: boolean;
}

export const useHistoricoStyles = makeStyles((theme) => ({
    containerRoot: {
        padding: '8px'
    },
    root: {
        height: '100vh',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    containerHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    containerTerminal: {
        display: 'flex',
        background: theme.palette.primary.main,
        borderRadius: '5px',
        justifyContent: 'center',
        padding: '8px',
        alignItems: 'center',
        width: '200px',
        height: '10px'
    },
    containerEmissao: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    textoTerminal: {
        color: '#FFF'
    },
    modal: {
        width: '480px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerInfo: {
        margin: '8px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 8px',
        }
    },
    containerSegundo: {
        margin: '8px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        }
    },
    containerId: {
        margin: '8px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        }
    },
    containerTerceiro: {
        margin: '8px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        }
    },
    textCardPag: {
        fontSize: '16px',
        fontWeight: 700,
    },
    content: {
        margin: '15px 25px',
        padding: '14px 22px'
    },
    toast: {
        width: '100%',
        padding: '25px',
        marginTop: '10px',
        textAlign: 'center'
    },
    title: {
        whiteSpace: 'nowrap'
    },
    back: {
        position: 'absolute',
        marginTop: '10px',
    },
    setor: {
        backgroundColor: '#F08324',
        padding: '3px 10px',
        borderRadius: '5px'
    },
    titleTerminal: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            fontWeight: 700,
            color: theme.palette.secondary,
            textAlign: 'center',
        }
    },
    divider: {
        background: theme.palette.grey[400]
    },
    cupomContainer: {
        marginLeft: '0px'
    },
    button: (props: StylesProps) => ({
        '& svg': {
            transform: props.open ? 'rotate(-90deg)' : 'rotate(90deg)'
        }
    })
}))