import { useCallback } from 'react';
import toast from 'react-hot-toast';

export type ToastTipo =
    | "none"
    | "success"
    | "error"
    | "item"
    | "pagamento"
    | "info"
    | "confirm";

export type ToastPosicao =
    | "top-center"
    | "top-right"
    | "top-left"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"

export function useToastSaurus() {
    const defaultTimeout = 4000;
    const defaultTimeoutSuccess = 2000;
    const defaultPosition = 'bottom-center';


    const reallyShowToast = useCallback((tipo: ToastTipo, component: any, timeout?: number, posicao?: ToastPosicao, marginBottom?: any) => {

        if (timeout === undefined)
            timeout = tipo === "success" ? defaultTimeoutSuccess : defaultTimeout;

        switch (tipo) {
            case 'success':
                toast.success(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        fontWeight: 600,
                        color: '#666',
                        marginBottom: marginBottom
                    },
                    duration: timeout ? timeout : defaultTimeoutSuccess
                });
                break;
            case 'error':
                toast.error(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        fontWeight: 600,
                        color: '#666',
                        marginBottom: marginBottom
                    },
                    duration: timeout ? timeout : defaultTimeout
                });
                break;
            case 'pagamento':
                toast.success(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        backgroundColor: 'rgb(0,0,0,.4)',
                        color: '#FFF',
                        padding: 1,
                        fontSize: 14,
                        marginBottom: marginBottom
                    },
                    duration: timeout ? timeout : defaultTimeout
                });
                break;
            case 'item':
                toast(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        background: 'rgba(0,0,0,.5)',
                        color: '#FFF',
                        padding: 1,
                        fontSize: 14,
                        marginBottom: marginBottom
                    },
                    duration: timeout ? timeout : defaultTimeout
                });
                break;
            case 'info':
                toast(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        fontWeight: 600,
                        color: '#666',
                        marginBottom: marginBottom
                    },
                    duration: timeout ? timeout : defaultTimeout
                });
                break;
            case 'confirm':
                toast.custom(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        fontWeight: 600,
                        color: '#666',
                    },
                    duration: timeout ? timeout : defaultTimeoutSuccess
                });
                break;
            case 'none':
                toast(component, {
                    position: posicao ? posicao : defaultPosition,
                    style: {
                        fontWeight: 600,
                        maxWidth: '500px',
                        color: '#666',
                        marginBottom: marginBottom || 10
                    },
                    duration: 2000
                });
                break;
        }

    }, []);

    const showToast = useCallback(
        (tipo: ToastTipo, mensagem: string, timeout?: number, posicao?: ToastPosicao, marginBottom?: any) => {
            if (mensagem !== undefined && mensagem !== "")
                reallyShowToast(tipo, mensagem, timeout, posicao, marginBottom);
        }, [reallyShowToast]);

    const showToastPersonalizado = useCallback(
        (tipo: ToastTipo, component: React.ReactNode, timeout?: number, posicao: ToastPosicao = "bottom-center") => {
            if (component !== undefined)
                reallyShowToast(tipo, component, timeout, posicao);
        }, [reallyShowToast]);
    return {
        showToast,
        showToastPersonalizado
    };
}
