import { makeStyles } from "@material-ui/core"


export const useStyles = makeStyles((theme) => ({
    card: {
        width: '700px',
        padding: '16px',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 41.9453px 109.058px rgba(0, 0, 0, 0.05)',
        borderRadius: '21px',
        [theme.breakpoints.down('xs')]: {
            width: '378px',
            height: '669px',
            background: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0px 41.9453px 109.058px rgba(0, 0, 0, 0.05)',
            borderRadius: '21px',
            margin: '0 15px'
        }
    },
    divider: {
        background: theme.palette.grey[300]
    },
    containerRoot: {
        marginTop: '40px'
    },
    gridTitle: {
        margin: '0px 0 40px 0'
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '30px',
        fontWeight: 700,
    },
    subTitle: {
        color: '#808080',
        fontSize: '17px',
        fontWeight: 600,
        marginTop: '22px',
        [theme.breakpoints.down('xs')]: {
            color: '#808080',
            fontSize: '16px',
            fontWeight: 600,
            marginTop: '22px'
        }
    },
    root: {
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flex: "1",
    }
})
)