import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
      root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    defaultContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        overflowX: 'auto',
        alignItems: 'center',
        justifyContent: 'center'
    },
    listContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        padding: '8px'
    },
    list: {
        flex: "1 1 100%",
        overflow: 'hidden',
    },
}));
