import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SessaoAtualProvider } from './services/providers/sessao-atual-provider/sessao-atual-provider';
import { Snackbar, Button, ThemeProvider } from '@material-ui/core';

import './assets/css/circular-loading.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SaurusTheme } from './views/theme';
import { toast, ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import { ConfirmProvider } from 'material-ui-confirm';

const HotToast = () => {
  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 1;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.remove(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <Toaster
      toastOptions={{
        style: {
          width: '100%',
        },
        duration: 3000,
      }}
      containerStyle={{
        top: 60,
        left: 16,
        bottom: 8,
        right: 16,
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={
            {
              ...t.style,
            }
          }
        >
          {({ icon, message }) => {
            return (
              <div
                onClick={() => {
                  toast.remove(t.id)
                }}
                style={{ display: 'flex', height: '100%', width: '100%' }}
              >
                {icon}
                {message}
              </div>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider
      theme={
        SaurusTheme
      }
    >
      <SessaoAtualProvider>
        <ConfirmProvider
          defaultOptions={{
            title: 'Confirma a Operação?',
            cancellationButtonProps: {
              variant: 'text',
              color: 'secondary',
              size: 'large'
            },
            confirmationButtonProps: {
              variant: 'contained',
              color: 'primary',
              size: 'large'
            },
            confirmationText: 'Sim',
            cancellationText: 'Não',
          }}
        >
          <App />
        </ConfirmProvider >
      </SessaoAtualProvider>
      <HotToast />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);



const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message="Uma nova versão está disponível!"
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          Atualizar
        </Button>
      }
    />
  );
}
export default ServiceWorkerWrapper;