import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        height: '350px',
        padding: '8px'
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        background: theme.palette.grey[300]
    },
    titleOpcoes: {
        width: '200px'
    }
}))