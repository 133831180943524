import { Box, Grid, Typography } from "@material-ui/core"
import { DefaultCard, useDefaultCardStyles } from "../../../../../../../../components/cards/components"
import { useStyles } from "./toast-scan-styles";
import { ConfirmadoTicketIcon } from "../../../../../../../../components/icons/confirmado-ticket-icon";
import { ErroIcon } from "../../../../../../../../components/icons/erro-icon";
import { toDecimalString } from "../../../../../../../../../utils/to-decimal";
import { formatDataHora } from "../../../../../../../../../utils/format-data-hora";
import { ProdModel } from "../../../../../../../../../model/api/Prod";
import classNames from "classnames";
import { isEmpty } from "lodash";

interface ToastScanProps {
    erro: any;
    model: ProdModel;
}

export const ToastScan = ({
    erro,
    model
}: ToastScanProps) => {

    const classes = useStyles({
        erro,
    });
    const defaultCardClasses = useDefaultCardStyles();

    return (
        <>
            <DefaultCard className={classes.root}>
                <div className={classes.status}>
                    {isEmpty(erro) ? (
                        <ConfirmadoTicketIcon tipo='GERAL' />
                    ) : (
                        <ErroIcon tipo="GERAL" />
                    )}
                </div>

                <Grid container>
                    <Grid item xs={12}>
                        <div className={defaultCardClasses.cardContent}>
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography
                                    variant="body2"
                                    className={classes.textItens}
                                >
                                    <Box fontWeight={700}>
                                    {isEmpty(erro) ? 'Entrega confirmada' :  'Entrega já registrada'}
                                    </Box>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={defaultCardClasses.cardContent}>
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography
                                    variant="caption"
                                    className={classes.textItens}
                                >
                                    Produto
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.textItens}
                                    style={{
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    <Box fontWeight={600}>
                                        {model.nome}
                                    </Box>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={defaultCardClasses.cardContent}>
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography
                                    variant="caption"
                                    className={classes.textItens}
                                >
                                    Leitura/Baixa
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.textItens}
                                    style={{
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {formatDataHora(model.data)}
                                </Typography>
                            </div>
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography
                                    variant="caption"
                                    className={classNames(classes.textItens, classes.containerItens)}
                                >
                                    Cod
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.textItens}
                                    style={{
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {model.codigo}
                                </Typography>
                            </div>
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography
                                    variant="caption"
                                    className={classes.textItens}
                                >
                                    Valor
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.textItens}
                                    style={{
                                        whiteSpace: 'normal'
                                    }}
                                >
                                    {`R$ ${toDecimalString(model.valor)}`}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid >
            </DefaultCard >
        </>
    )
}