import { useCallback } from "react";
import { useMenuPrincipal } from "../../../../../../services/providers/menu-principal-provider";
import { useThemeQueries } from "../../../../../theme";
import { ButtonPrivateHeader } from "../../../../../components/controles/buttons";
import { MenuIcon } from "../../../../../components/icons/menu-icon";
import { PrivatePageHeader } from "../../../../../components/headers/header-private-page";

export const SettingsHeader = () => {
   const { abrirMenu } = useMenuPrincipal()
   const { isMobile } = useThemeQueries()

   const leftArea = useCallback(
      () =>
         isMobile ? (
            <ButtonPrivateHeader
               icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
               tooltip="Menu"
               onClick={abrirMenu}
            ></ButtonPrivateHeader>
         ) : null,
      [isMobile, abrirMenu]
   );

   return (
      <>
         <PrivatePageHeader
            title="Configurações"
            leftArea={leftArea()}
         />
      </>
   )
}