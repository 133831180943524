import * as Yup from 'yup';
import { useValidationYupNome, useValidationYupSenha } from '../../form-validations';


export const useFormLoginValidation = () => {
    const { nomeYup } = useValidationYupNome()
    const { senhaYup } = useValidationYupSenha()

    const FormLoginYupValidation = Yup.object().shape({
        nomeTerminal: nomeYup(),
        senha: senhaYup()
    });

    return {
        FormLoginYupValidation
    }
}

