import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../components/styles/default-cadastro-styles';
import { LeitorHeader } from './components/leitor-header/leitor-header';
import { LeitorTabs } from './components/leitor-tabs/leitor-tabs';

export const LeitorPage = () => {
  const classes = useDefaultCadastroStyles();

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <LeitorHeader />
        </Grid>
        <Grid className={classes.list}>
          <LeitorTabs />
        </Grid>
      </Grid>
    </>
  );
};

export default LeitorPage;
