import { Box, Button, Card, Fade, Grid, Typography } from "@material-ui/core"
import { useHistory } from "react-router";
import { ConfirmarIcon } from "../../../components/icons/confirmar-icon";
import { makeUtilClasses } from "../../../theme";
import { useStyles } from "./first-access-page-styles";
import { PublicPageHeader } from "../../../components/headers/header-public-page";

export const FirstAccessPage = () => {
    const classes = useStyles();
    const utilClasses = makeUtilClasses();
    const history = useHistory();
    const redirectSenha = () => {
        history.push("/cadastro");
    }
    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <Fade in timeout={800} mountOnEnter unmountOnExit>
                <div className={utilClasses.contentCenter}>
                    <Card className={classes.cardLogin}>
                        <Grid container className={utilClasses.rootContainer}>
                            <Grid container alignItems="center" direction="column">
                                <Grid item xs={10} md={12}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.title}>Primeiro Acesso</Typography>
                                    </Grid>
                                    <Box mt={3}>
                                        <Typography className={classes.text}>  Verificamos que este é o seu primeiro acesso no Saurus Tickets.</Typography>
                                        <Typography className={classes.text}>Para continuar deve redefinir a sua senha, e continue com o acesso.</Typography>
                                    </Box>
                                    <div className={classes.divider} />
                                    <Box mt={10}>
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.button}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => redirectSenha()}
                                                fullWidth
                                            >
                                                <ConfirmarIcon tipo="BUTTON" />
                                                Confirmar
                                            </Button>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Fade>
        </>
    )
}