import { useCallback } from "react";
import { HistoricoListSearchProps } from "../../historico-list.tsx/historico-list-search-props";
import { PesquisarHistorico } from "../pesquisa-historico/pesquisa-historico";
import { useThemeQueries } from "../../../../../theme";
import { useMenuPrincipal } from "../../../../../../services/providers/menu-principal-provider";
import { ButtonPrivateHeader } from "../../../../../components/controles/buttons";
import { MenuIcon } from "../../../../../components/icons/menu-icon";
import { FiltroIcon } from "../../../../../components/icons/filtro-icon";
import { DesfazerIcon } from "../../../../../components/icons/desfazer-icon";
import { PrivatePageHeader } from "../../../../../components/headers/header-private-page";

export interface HistoricoHeaderProps {
   openPesquisa: boolean;
   setOpenPesquisa: (state: boolean) => any;
   searchProps: HistoricoListSearchProps;
   setSearchProps: React.Dispatch<React.SetStateAction<HistoricoListSearchProps>>
}

export const HistoricoHeader = ({
   openPesquisa,
   setOpenPesquisa,
   setSearchProps,
   searchProps
}: HistoricoHeaderProps) => {
   const { abrirMenu } = useMenuPrincipal()
   const { isMobile } = useThemeQueries()


   const leftArea = useCallback(
      () => (
         isMobile ?
            <ButtonPrivateHeader
               icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
               tooltip="Menu"
               onClick={abrirMenu}></ButtonPrivateHeader>
            :
            null
      ),
      [isMobile, abrirMenu]
   );
   const rightArea = useCallback(
      () => (
         <ButtonPrivateHeader
            icon={
               openPesquisa ?
                  <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
                  :
                  <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
            tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
            onClick={() => setOpenPesquisa(!openPesquisa)}
         ></ButtonPrivateHeader>
      ),
      [setOpenPesquisa, openPesquisa]
   );
   const closePesquisaWrapper = useCallback(() => {
      setOpenPesquisa(false);
   }, [setOpenPesquisa]);

   const onPesquisar = useCallback(
      (props: HistoricoListSearchProps) => {
         setSearchProps(props);
      },
      [setSearchProps]
   );

   const pesquisa =
      <PesquisarHistorico
         isOpened={openPesquisa!}
         onCloseSearch={closePesquisaWrapper}
         setSearchProps={setSearchProps}
         searchProps={searchProps}
         onPesquisar={onPesquisar}
      />

   return (
      <>
         <PrivatePageHeader
            title="Histórico de Leitura"
            leftArea={leftArea()}
            bottomArea={pesquisa}
            rightArea={rightArea()}
         />
      </>
   )
}