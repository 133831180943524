import React from "react";
import { MenuProviderModelo } from "./menu-principal-provider-types"
import { useThemeQueries } from "../../../views/theme";
import { useMenuStorage } from "../../use-cases/menu-storage";
import { useGlobalStateTools } from "../../use-cases/global-states/global-state-tools";


interface MenuPrincipalContextValue {
    abrirMenu: () => Promise<void>;
    fecharMenu: () => Promise<void>;
    alterarModelo: (modelo: MenuProviderModelo) => Promise<void>;
}

const MenuPrincipalContext = React.createContext<MenuPrincipalContextValue>({
    abrirMenu: () => { return new Promise<void>(() => true) },
    fecharMenu: () => { return new Promise<void>(() => true) },
    alterarModelo: (modelo: MenuProviderModelo) => { return new Promise<void>(() => true) },
});


export interface MenuPrincipalProviderProps {
    children: React.ReactNode;
}

export const useMenuPrincipal = () => {
    return React.useContext(MenuPrincipalContext);
};

export const MenuPrincipalProvider = ({ children }: MenuPrincipalProviderProps) => {
    const { isMobile } = useThemeQueries();
    const { get, persist } = useMenuStorage();
    const { changeMenuState } = useGlobalStateTools();

    const abrirMenu = React.useCallback(async (): Promise<void> => {
        var menuAtual = get();
        menuAtual.situacao = "Aberto"

        if (!isMobile)
            persist(menuAtual);

        changeMenuState(menuAtual);
    }, [changeMenuState, get, isMobile, persist]);

    const fecharMenu = React.useCallback(async (): Promise<void> => {
        var menuAtual = get();
        menuAtual.situacao = "Fechado";

        changeMenuState(menuAtual);
        persist(menuAtual);
    }, [changeMenuState, get, persist]);

    const alterarModelo = React.useCallback(async (modelo: MenuProviderModelo): Promise<void> => {
        var menuAtual = get();
        menuAtual.modelo = modelo;

        changeMenuState(menuAtual);
        persist(menuAtual);
    }, [changeMenuState, get, persist]);

    const content = React.useMemo(() => {
        console.log('MenuPrincipalProvider-content');
        return children;
    }, [children]);

    console.log('MenuPrincipalProvider');
    return (
        <MenuPrincipalContext.Provider
            value={{
                abrirMenu,
                fecharMenu,
                alterarModelo
            }}
        >
            {content}
        </MenuPrincipalContext.Provider>
    );
};