import { Grid } from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import { HistoricoList } from "./historico-list.tsx/historico-list";
import { HistoricoHeader } from "./components/historico-header/historico-header";
import { useState } from "react";
import { HistoricoListSearchProps } from "./historico-list.tsx/historico-list-search-props";
import { useDefaultHistoricoStyles } from "./historico-page-styles";

export const HistoricoPage = () => {
    const classes = useDefaultHistoricoStyles();
    const [openPesquisa, setOpenPesquisa] = useState(false);

    const [searchProps, setSearchProps] = useState<HistoricoListSearchProps>({
        dFinal: '',
        dInicial: '',
    })

    return (
        <>
            <Grid className={classes.root}>
                <Grid className={classes.header}>
                    <HistoricoHeader
                        openPesquisa={openPesquisa}
                        setOpenPesquisa={setOpenPesquisa}
                        setSearchProps={setSearchProps}
                        searchProps={searchProps}
                    />
                </Grid>
                <Grid className={classes.list}>
                    <Grid item xs={12} className={classes.containerList}>
                        <HistoricoList searchProps={searchProps} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}