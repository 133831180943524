import { useCallback } from "react";
import { TokenModel } from '../../model/api/token/token-model'
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
export const useBaixasToken = () => {
    const decodeToken = useCallback(
        (token: string): TokenModel | undefined => {
            let gtoken: TokenModel | undefined;
            try {
                gtoken = jwtDecode<TokenModel>(token);
            }
            catch (e) {
                gtoken = undefined;
            }
            return gtoken;
        },
        [],
    )
    const isTokenExpired = useCallback(
        (token: TokenModel | undefined): boolean => {
            if (!token?.exp) {
                return true;
            }
            return token?.exp * 1000 <= new Date().getTime();
        },
        [],
    )
    const isTokenValid = useCallback(
        (token: TokenModel | undefined) => {
            return !isEmpty(token) && !isTokenExpired(token)
        },
        [isTokenExpired])

    return {
        isTokenExpired,
        isTokenValid,
        decodeToken

    }
}