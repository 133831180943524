import { useSessaoAtual } from "../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { Box, Button, Card, Divider, Fade, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./cadastro-page-styles"
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router";
import { makeUtilClasses } from "../../../theme";
import { SalvarIcon } from "../../../components/icons/salvar-icon";
import { VoltarSairIcon } from "../../../components/icons/voltar-sair-icon ";
import { PublicPageHeader } from "../../../components/headers/header-public-page";
import { UserDadosModel } from "../../../../model/api/UserDados/user-dados-model";
import { picker } from "../../../../utils/picker";
import { isEmpty, isEqual } from "lodash";
import { FormCadastro } from "../../../components/form/form-cadastro/form-cadastro";
import { DefaultFormRefs } from "../../../components/form/utils";
import { useEffect, useRef, useState } from "react";
import { TicketStorageKeys, useTicketStorage } from "../../../../services/use-cases/ticket-storage";
import { useToastSaurus } from "../../../../services/use-cases/toast-saurus";

export const CadastroPage = () => {

    //AUX
    const classes = useStyles();
    const utilClasses = makeUtilClasses();
    const history = useHistory();
    const { getRegistro } = useTicketStorage();
    const { showToast } = useToastSaurus();

    //PROVIDERS
    const { changeUserPassword } = useSessaoAtual();

    //STATES E REFS
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const usuarioEditFormRef = useRef<DefaultFormRefs<UserDadosModel>>(null);

    const handleSubmmit = (values: UserDadosModel) => {

        const modelStorage = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel

        if (!isEmpty(modelStorage)) {
            if (isEqual(modelStorage, values)) {
                showToast('info', 'Nenhuma informação alterada');
                return
            }
        }

        const password = picker<UserDadosModel>(
            values,
            new UserDadosModel(),
        );

        const mudouSenha = !isEmpty(password.senha);
        changePassword(password, mudouSenha);
    };

    const changePassword = (model: UserDadosModel, mudouSenha: boolean) => {
        changeUserPassword(model, mudouSenha);
        window.location.href = "/"
    }

    useEffect(() => {
        setPreenchendoTela(prev => !prev);
    }, []);

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <Fade in mountOnEnter unmountOnExit>
                <div className={utilClasses.contentCenter}>
                    <Card className={classes.card}>
                        <Box className={classes.gridTitle}>
                            <Typography
                                className={classes.title}
                            >
                                Configurações
                            </Typography>
                            <Typography
                                className={classes.subTitle}
                            >
                                Informe os campos abaixo para confirmar este terminal.
                            </Typography>
                        </Box>
                        <FormCadastro
                            ref={usuarioEditFormRef}
                            loading={preenchendoTela}
                            showLoading={false}
                            onSubmit={handleSubmmit}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider variant="fullWidth" className={classes.divider} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => usuarioEditFormRef.current?.submitForm()}
                                    fullWidth
                                >
                                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => history.goBack()}
                                >
                                    <VoltarSairIcon tipo="BUTTON" />
                                    Voltar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Fade>
        </>
    )
}