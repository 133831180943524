import { useEffect, useState, useCallback } from "react";
import { Fade, Grid } from "@material-ui/core";
import { useStyles } from "./historico-list-styles";
import { HistoricoListData } from "./historico-list-data";
import { isEmpty } from "lodash";
import { HistoricoListSearchProps } from "./historico-list-search-props";
import { HistoricoModel } from "../../../../../model/api/Historico/historico-model";
import { useSessaoAtual } from "../../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { useGetHistorico } from "../../../../../data/api/baixas/historico/get-historico";
import { stringNumeros } from "../../../../../utils/string-numeros";
import { KeyValueModel } from "../../../../../model/api/utils";
import { CircularLoading } from "../../../../components/utils/circular-loading";
import { Paginacao } from "../../../../components/paginacao";

interface HistoricoListProps {
  searchProps: HistoricoListSearchProps;
}

export const HistoricoList = (({ searchProps }: HistoricoListProps) => {

  const classes = useStyles();
  const [queryStatus, setQueryStatus] = useState({ page: 1, totalPages: 0, totalResults: 0, list: Array<HistoricoModel>() });
  const { getHistorico, carregando } = useGetHistorico()
  const { getData } = useSessaoAtual()
  const [statusPesquisar] = useState<any>(0);

  const fillResult = useCallback(async (page: number, totalPages: number, totalResults: number, list: Array<HistoricoModel>) => {
    setQueryStatus({ page: page, list: list, totalResults: totalResults, totalPages: totalPages });
  }, []);

  const dataFormatada = useCallback((val) => {
    const data = new Date(val)
    const dia = data.getDate() + 1
    const mes = data.getMonth() + 1
    const ano = data.getFullYear()
    return `${ano}-${mes.toString().length === 1 ? '0' + mes : mes}-${dia.toString().length === 1 ? '0' + dia : dia}`
  }, [])

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(searchProps.dInicial)
          ? `&DataInicial=${dataFormatada(searchProps.dInicial)}T00:00:00`
          : '') +
        (!isEmpty(searchProps.dFinal)
          ? `&DataFinal=${dataFormatada(searchProps.dFinal)}T23:59:59.396`
          : '');
      try {
        const res = await getHistorico(stringNumeros(getData()?.cnpj || ''), newPage, query);

        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages && res.resultado?.data?.totalResults > 0) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

      } catch (e: any) {
        console.error("error", e.message);
      }
    },
    [dataFormatada, fillResult, getData, getHistorico, searchProps.dFinal, searchProps.dInicial]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  useEffect(() => {
    setTimeout(() => {
      search(1)
    }, 100)
  }, [search]);

  const unique = Array.from(
    new Set<string>(queryStatus.list.map((item) => item.terminal)),
  );
  const mock: Array<KeyValueModel> = unique.map((item, index) => {
    return new KeyValueModel((index + 1).toString(), item)
  })
  mock.push(new KeyValueModel('0', 'Todos'))


  return (
    <>
      {carregando && (<CircularLoading tipo="FULLSIZED" />)}
      <Fade in timeout={900} mountOnEnter unmountOnExit>
        <div className={classes.defaultContainer}>
          <Grid container>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={statusPesquisar !== '0' && statusPesquisar
                ? queryStatus.list.filter(item => item.terminal === mock.filter(item => item.Key === statusPesquisar).map(item => item.Value)[0]).length :
                queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
            <Grid item xs={12} className={classes.listContainer}>
              <HistoricoListData
                carregando={carregando}
                list={!statusPesquisar || statusPesquisar === '0'
                  ? queryStatus.list
                  : queryStatus.list.filter(item => item.terminal === mock.filter(item => item.Key === statusPesquisar).map(item => item.Value)[0])}
              />
            </Grid>
          </Grid>
        </div>
      </Fade>
    </>
  );
}
);
