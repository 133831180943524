import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { usePostIdentidadeAutenticar } from '../../../data/api/baixas/auth/auth-service'
import { AuthModel } from "../../../model/api/Auth/auth-model"
import { useBaixasToken } from "../../use-cases/baixas-token"
import { senhaSync } from '../../../views/components/utils/senhaSync'
import { TicketStorageKeys, useTicketStorage } from "../../use-cases/ticket-storage"
import { isEmpty, isEqual } from "lodash"
import { UserDadosModel } from "../../../model/api/UserDados"

export interface SessaoAtualContextValues {
    Login: (nomeTerminal: string, senha: string) => Promise<void>,
    carregando: boolean,
    Logout: () => void,
    changeUserPassword: (model: UserDadosModel, mudouSenha: boolean) => void
    FirstAccess: () => boolean,
    IsValidSession: () => boolean,
    getData: () => UserDadosModel | undefined;
    logado: boolean;
    setLogado: (value: boolean) => void;
}
const SessaoAtualContext = createContext<SessaoAtualContextValues>({
    Login: (nomeTerminal: string, senha: string) => { return new Promise<void>(() => true) },
    carregando: false,
    changeUserPassword: (model: UserDadosModel, mudouSenha: boolean) => { },
    IsValidSession: () => { return false },
    FirstAccess: () => { return false },
    Logout: () => { },
    getData: () => undefined,
    logado: false,
    setLogado: (value: boolean) => { },
})
interface Props {
    children: React.ReactNode;
}
export const SessaoAtualProvider = ({ children }: Props) => {
    const { delRegistro, exists, getRegistro, setRegistro } = useTicketStorage()
    const { isTokenValid, decodeToken } = useBaixasToken()
    const { obterSenhaSyncAtual } = senhaSync();
    const [logado, setLogado] = useState<boolean>(!isEmpty(getRegistro(TicketStorageKeys.AuthToken)))
    const { postIdentidadeAutenticar, carregando: carregandoApiAutenticar } =
        usePostIdentidadeAutenticar();
    const data = useRef<UserDadosModel | undefined>(undefined);

    const loading =
        carregandoApiAutenticar

    const Logout = () => {
        delRegistro(TicketStorageKeys.AuthToken, false)
        setLogado(false)
    }

    const getData = React.useCallback((): UserDadosModel | undefined => {
        return data.current;
    }, []);

    const objetoData = useCallback(() => {
        const model = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel

        const obj = {
            nomeTerminal: !isEmpty(model.nomeTerminal) ? model.nomeTerminal : '',
            cnpj: !isEmpty(model.cnpj) ? model.cnpj : '',
            tpLeitor: model.tpLeitor,
            senha: !isEmpty(model.senha) ? model.senha : '',
            confirmarSenha: !isEmpty(model.confirmarSenha) ? model.confirmarSenha : '',
        }
        if (!data.current) {
            data.current = obj
        }
    }, [getRegistro])

    useEffect(() => {
        getData()
        objetoData()
    }, [objetoData, getData])

    const Login = useCallback(async (nomeTerminal: string, senha: string) => {

        const model = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel;

        if (isEqual(senha, model.senha) || senha === obterSenhaSyncAtual()) {
            let res = await postIdentidadeAutenticar(new AuthModel("@@ophd0202$sig-AuthPass"));
            if (res.erro) {
                throw new Error(res.erro)
            }
            setRegistro(TicketStorageKeys.AuthToken, res.resultado?.data, false)
            objetoData()
            setLogado(true)
        }
        else {
            throw new Error('Senha incorreta');
        }
    }, [getRegistro, obterSenhaSyncAtual, postIdentidadeAutenticar, setRegistro, objetoData])

    const FirstAccess = useCallback((): boolean => {
        return !exists(TicketStorageKeys.UsuarioAtual, false)
    }, [exists])

    const changeUserPassword = useCallback(
        (model: UserDadosModel) => {
            try {
                setRegistro(TicketStorageKeys.UsuarioAtual, model, false);
                objetoData()
            } catch (e: any) {
                console.log(e.message)
            }
        }, [setRegistro, objetoData]
    )

    const IsValidSession = useCallback((): boolean => {
        return isTokenValid(decodeToken(getRegistro(TicketStorageKeys.AuthToken, false)))
    }, [decodeToken, getRegistro, isTokenValid])

    return (
        <SessaoAtualContext.Provider value={{
            Login,
            carregando: loading,
            Logout,
            changeUserPassword,
            IsValidSession,
            FirstAccess,
            getData,
            logado,
            setLogado
        }}>
            {children}
        </SessaoAtualContext.Provider>
    )
}

export const useSessaoAtual = () => {
    return useContext(SessaoAtualContext);
}