import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { DefaultFormProps, DefaultFormRefs } from '../utils';
import { makeUtilClasses, useThemeQueries } from '../../../theme';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Grid } from '@material-ui/core';
import { CircularLoading } from '../../utils/circular-loading';
import { TextFieldSaurus } from '../../controles/inputs';
import { useFormLoginValidation } from './form-login-validation';
import { DadosLoginModel } from '../../../../model/api/login/dados-login-model';
import { useStyles } from './form-login-styles';
import { yupResolver } from '@hookform/resolvers/yup';


export const FormLogin = forwardRef<
  DefaultFormRefs<DadosLoginModel>,
  DefaultFormProps<DadosLoginModel>
>((props: DefaultFormProps<DadosLoginModel>, refs) => {
  const txtUsuario = useRef<HTMLInputElement>();
  const txtSenha = useRef<HTMLInputElement>();
  const { FormLoginYupValidation } = useFormLoginValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const [model, setModel] = useState({
    modeloForm: new DadosLoginModel('', ''),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<DadosLoginModel>({
    resolver: yupResolver(FormLoginYupValidation),
    defaultValues: { ...model.modeloForm },
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const onSubmit = (form: DadosLoginModel) => {
    console.log(form)
    props.onSubmit(form);
  };


  useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) txtUsuario?.current?.focus();
    },
    fillForm: (model: DadosLoginModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) {


        if (model.nomeTerminal.length > 0)
          txtSenha?.current?.focus();
        else
          txtUsuario?.current?.focus();
      }
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="nomeTerminal"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="USUARIO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment
                    inputRef={txtUsuario}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Nome do Terminal"
                    placeholder="Digite o nome do terminal"
                    error={Boolean(errors.nomeTerminal && errors.nomeTerminal.message)}
                    helperText={
                      touchedFields.nomeTerminal || errors.nomeTerminal
                        ? errors.nomeTerminal?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={5}
                mb={Boolean(touchedFields.senha && errors.senha) ? 0 : 1.5}
              >
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit={true}
                      disabled={props.loading}
                      showStartAdornment
                      fullWidth={true}
                      inputRef={txtSenha}
                      autoComplete={'off'}
                      label="Senha"
                      placeholder="Digite sua Senha"
                      error={Boolean(errors.senha && errors.senha.message)}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
