import { Button, Grid } from "@material-ui/core";
import { useThemeQueries } from "../../../theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { VoltarIcon } from "../../icons/voltar-icon";
import { LoginIcon } from "../../icons/login-icon";
import { FormConfirmacaoUsuario } from "../../form/form-confirmacao-usuario/form-confirmacao-usuario";
import { useCallback, useRef } from "react";
import { DefaultFormRefs } from "../../form/utils";
import { FormConfirmacaoUsuarioModel } from "../../../../model/app/form/form-confirmacao-usuario-model";
import { useToastSaurus } from "../../../../services/use-cases/toast-saurus";
import { useHistory } from "react-router";
import { senhaSync } from "../../utils/senhaSync";
import { useStyles } from "./dialog-confirmacao-usuario-styles";

interface DialogConfirmacaoUsuarioProps {
    aberto: boolean;
    setAbrirDialog: (value: React.SetStateAction<boolean>) => void
}

export const DialogConfirmacaoUsuario = ({
    aberto,
    setAbrirDialog,
}: DialogConfirmacaoUsuarioProps) => {

    //AUX
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus();
    const history = useHistory();
    const classes = useStyles();

    const { obterSenhaSyncAtual } = senhaSync();

    console.log(obterSenhaSyncAtual())

    //REFS
    const refFormConfirmacaoUsuario = useRef<DefaultFormRefs<FormConfirmacaoUsuarioModel>>(null)

    const handleSubmmit = useCallback((model: FormConfirmacaoUsuarioModel) => {

        if (model.senha !== obterSenhaSyncAtual()) {
            showToast('error', 'Senha incorreta.')
            return
        }

        history.push('/cadastro')
    }, [history, obterSenhaSyncAtual, showToast])

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                titulo='Senha Sync'
                colorTitulo={theme.palette.primary.main}
                isButtonTitleClose
                handleClickClose={() => setAbrirDialog(false)}
                tamanho='sm'
                bottomArea={
                    <>
                        <div className={classes.containerButtons}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        onClick={() => setAbrirDialog(false)}
                                    >
                                        <VoltarIcon tipo="BUTTON" />
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => refFormConfirmacaoUsuario.current?.submitForm()}
                                    >
                                        <LoginIcon tipo="BUTTON_PRIMARY" />
                                        Entrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                }
            >
                <FormConfirmacaoUsuario
                    ref={refFormConfirmacaoUsuario}
                    loading={false}
                    onSubmit={handleSubmmit}
                    showLoading={false}
                />
            </DialogSaurus>
        </>
    )
}