export const formatData = (hora: Date) => {

   function padTo2Digits(num: number) {
      return (
         num.toString().padStart(2, '0')

      );
   }
   const hasHora = new Date(hora)
   const ano = hasHora.getFullYear()
   const dia = padTo2Digits(hasHora.getDate())
   const mes = padTo2Digits(hasHora.getMonth() + 1)

   return `${dia}/${mes}/${ano}`
}
