import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { DefaultFormProps, DefaultFormRefs } from '../utils';
import { makeUtilClasses, useThemeQueries } from '../../../theme';
import { Controller, useForm } from 'react-hook-form';
import { Box, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { CircularLoading } from '../../utils/circular-loading';
import { TextFieldSaurus } from '../../controles/inputs';
import { useSessaoAtual } from '../../../../services/providers/sessao-atual-provider/sessao-atual-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isEqual } from 'lodash';
import { useFormDadosPageValidation } from './form-dados-validation';
import { useToastSaurus } from '../../../../services/use-cases/toast-saurus';
import { TicketStorageKeys, useTicketStorage } from '../../../../services/use-cases/ticket-storage';
import { SaurusRadio } from '../../controles/radio/saurus-radio';
import { UserDadosModel } from '../../../../model/api/UserDados';
import { EnumTpLeitor } from '../../../../model/enums/enum-tp-leitor';
import { useStyles } from './form-dados-styles';

export const FormDados = forwardRef<
  DefaultFormRefs<UserDadosModel>,
  DefaultFormProps<UserDadosModel>
>(({ loading, ...props }: DefaultFormProps<UserDadosModel>, ref) => {

  //AUX
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus()
  const { getRegistro } = useTicketStorage()
  const modelStorage = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel;

  //PROVIDERS
  const { changeUserPassword } = useSessaoAtual();

  //REFS E STATES
  const refInput = useRef<HTMLInputElement>(null);
  const [model, setModel] = useState<UserDadosModel>(modelStorage);
  const [tpLeitor, setTpLeitor] = useState<EnumTpLeitor>(model.tpLeitor);

  //VALIDATION
  const { FormDadosPageValidation } = useFormDadosPageValidation()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<UserDadosModel>({
    defaultValues: { ...model },
    criteriaMode: 'all',
    resolver: yupResolver(FormDadosPageValidation),
    mode: 'onChange',
  });

  const onSubmit = async (values: UserDadosModel) => {
    const modelEqual = isEqual(values, model)

    if (modelEqual) {
      showToast('info', 'Nenhuma informação foi alterada.')
      return
    }

    changePassword(values)
  };

  const changePassword = (model: UserDadosModel) => {
    const mudouSenha = !isEmpty(model.senha);

    changeUserPassword(model, mudouSenha);
    window.location.href = "/"
  }

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInput.current?.focus();
    },
    fillForm: (model: UserDadosModel) => {
      setModel(model);
      setTimeout(() => {
        if (!isMobile) refInput.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      {!loading && (
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      className={classes.title}
                    >
                      Dados
                    </Typography>
                  </Box>
                  <Divider variant='fullWidth' className={classes.divider} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name="nomeTerminal"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        label="Usuário"
                        autoComplete="off"
                        placeholder="Digite o Nome do usuário"
                        fullWidth={true}
                        variant="outlined"
                        error={Boolean(errors.nomeTerminal && errors.nomeTerminal.message)}
                        helperText={
                          touchedFields.nomeTerminal || errors.nomeTerminal
                            ? errors.nomeTerminal?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name="cnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        label="CNPJ"
                        autoComplete="off"
                        placeholder="Digite o Nome do Terminal"
                        tipo="CNPJ"
                        fullWidth={true}
                        variant="outlined"
                        error={Boolean(errors.cnpj && errors.cnpj.message)}
                        helperText={
                          touchedFields.cnpj || errors.cnpj
                            ? errors.cnpj?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    className={classes.title}
                  >
                    Modelo de Operação
                  </Typography>
                  <Divider variant='fullWidth' className={classes.divider} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    label='Leitor Por Câmera'
                    style={{
                      width: '200px'
                    }}
                    onClick={() => {
                      setTpLeitor(EnumTpLeitor.LeitorCamera)
                      setValue('tpLeitor', EnumTpLeitor.LeitorCamera)
                    }}
                    checked={tpLeitor === EnumTpLeitor.LeitorCamera}
                    control={<SaurusRadio />}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    label='Leitor Por Teclado (USB/Bluetooth)'
                    style={{
                      width: '200px'
                    }}
                    onClick={() => {
                      setTpLeitor(EnumTpLeitor.LeitorTeclado)
                      setValue('tpLeitor', EnumTpLeitor.LeitorTeclado)
                    }}
                    checked={tpLeitor === EnumTpLeitor.LeitorTeclado}
                    control={<SaurusRadio />}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    label='Leitor Por Câmera e Teclado'
                    style={{
                      width: '200px'
                    }}
                    onClick={() => {
                      setTpLeitor(EnumTpLeitor.LeitorCameraTeclado)
                      setValue('tpLeitor', EnumTpLeitor.LeitorCameraTeclado)
                    }}
                    checked={tpLeitor === EnumTpLeitor.LeitorCameraTeclado}
                    control={<SaurusRadio />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.title}
                  >
                    Senha
                  </Typography>
                  <Divider variant='fullWidth' className={classes.divider}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name="senha"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo='PASSWORD'
                        disabled={loading}
                        showPasswordVisibilityButton={true}
                        label="Senha"
                        autoComplete="off"
                        placeholder="Digite sua senha"
                        fullWidth={true}
                        variant="outlined"
                        error={Boolean(errors.senha && errors.senha.message)}
                        helperText={
                          touchedFields.senha || errors.senha
                            ? errors.senha?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name="confirmarSenha"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo='PASSWORD'
                        disabled={loading}
                        showPasswordVisibilityButton={true}
                        label="Confirmar Senha"
                        autoComplete="off"
                        placeholder="Digite Novamente sua senha"
                        fullWidth={true}
                        variant="outlined"
                        allowSubmit
                        error={Boolean(errors.confirmarSenha && errors.confirmarSenha.message)}
                        helperText={
                          touchedFields.confirmarSenha || errors.confirmarSenha
                            ? errors.confirmarSenha?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      )}
    </>
  );
});
