import { useSessaoAtual } from "../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { Button, Card, Divider, Fade, Grid } from "@material-ui/core";
import { SalvarIcon } from "../../icons/salvar-icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { DefaultFormRefs } from "../../form/utils";
import { UserDadosModel } from "../../../../model/api/UserDados/user-dados-model";
import { isEmpty } from "lodash";
import { FormDados } from "../../form/form-dados/form-dados";
import { useConfiguracaoStyles } from "./card-configuracao-styles";
import { VoltarSairIcon } from "../../icons/voltar-sair-icon ";
import { useHistory } from "react-router";
import { useConfirm } from 'material-ui-confirm';

export const CardConfiguracao = () => {

    //AUX
    const classes = useConfiguracaoStyles();
    const history = useHistory();

    //PROVIDERS
    const confirm = useConfirm();
    const { changeUserPassword } = useSessaoAtual();

    //STATES E REFS
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const usuarioEditFormRef = useRef<DefaultFormRefs<UserDadosModel>>(null);

    const onSubmit = (values: UserDadosModel) => {
        const mudouSenha = !isEmpty(values.senha);

        changePassword(values, mudouSenha)
    };

    const changePassword = (userModel: UserDadosModel, mudouSenha: boolean) => {
        changeUserPassword(userModel, mudouSenha);
        window.location.href = "/"
    }

    const limparDados = useCallback(() => {
        confirm({
            title: 'Confirma a Restauração?',
            description:
                'Deseja restaurar a aplicação neste dispositivo e configurá-lo novamente?',
            cancellationText: 'Cancelar',
            cancellationButtonProps: {
                color: 'default',
            },
            confirmationText: 'Restaurar',
        }).then(() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/primeiro-acesso');
        });
    }, [confirm, history]);

    useEffect(() => {
        setPreenchendoTela(prev => !prev);
    }, []);

    return (
        <>
            <Fade in timeout={900} mountOnEnter unmountOnExit>
                <div className={classes.contentCenter}>
                    <Card className={classes.card}>
                        <Grid container spacing={4}>
                            <Grid item md={12} xs={12}>
                                <FormDados
                                    ref={usuarioEditFormRef}
                                    loading={preenchendoTela}
                                    showLoading={false}
                                    onSubmit={onSubmit}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider
                                    variant="fullWidth"
                                    className={classes.divider}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={limparDados}
                                >
                                    <VoltarSairIcon tipo="BUTTON" />
                                    Restaurar Dispositivo
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() => usuarioEditFormRef.current?.submitForm()}
                                    fullWidth
                                >
                                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Fade>
        </>
    )
}