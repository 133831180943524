
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupSenha, useValidationYupSenhaConfirmacao } from '../../form-validations';
import { useValidationYupDocumento } from '../../form-validations/yup/documento';

export const useFormDadosPageValidation = () => {

  const { documentoYup } = useValidationYupDocumento(true)
  const { senhaYup } = useValidationYupSenha()
  const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

  const FormDadosPageValidation = useMemo(() => {
    return Yup.object().shape({
      cnpj: documentoYup('CNPJ'),
      senha: senhaYup(false),
      confirmarSenha: Yup.string().when('senha', (senha: any) => {
        if (!isEmpty(senha)) {
          return senhaConfirmacaoYup('senha')
        }
        return Yup.string().notRequired()
      })
    });
  }, [documentoYup, senhaConfirmacaoYup, senhaYup])

  return {
    FormDadosPageValidation,
  }
}

