import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ConfirmarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="21.9219" stroke="#fff" stroke-width="2.15625" />
          <path d="M16.5854 27.2439L22.2839 32.5031L33.9756 17.7073" stroke="#fff" stroke-width="2.875" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </DefaultIcon>
    </>
  );
};
