import { Button, Fade, Grid } from '@material-ui/core';
import { HistoricoListSearchProps } from '../../historico-list.tsx/historico-list-search-props';
import { useStyles } from './pesquisa-historico-styles';
import { TextFieldSaurus } from '../../../../../components/controles/inputs';
import { useCallback, useState } from 'react';
import { ProcurarIcon } from '../../../../../components/icons/procurar-icon';


export interface PesquisarHistoricoProps {
  onCloseSearch: () => void;
  isOpened: boolean;
  searchProps: HistoricoListSearchProps
  setSearchProps: React.Dispatch<React.SetStateAction<HistoricoListSearchProps>>
  onPesquisar: (props: HistoricoListSearchProps) => any;
}

export const PesquisarHistorico = (props: PesquisarHistoricoProps) => {
  const classes = useStyles()

  const [dInicial, setDInicial] = useState<string>('');
  const [dFinal, setDFinal] = useState<string>('');

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({ dInicial: dInicial, dFinal: dFinal });
    },
    [dFinal, dInicial, props],
  );

  return (
    <>
      <Fade in={props.isOpened} mountOnEnter unmountOnExit>
        <form onSubmit={pesquisar}>
          <Grid container spacing={1} className={classes.root}>
            <Grid item md={6} xs={6}>
              <TextFieldSaurus
                tipo='DATA'
                variant="outlined"
                label='Data Inicial'
                size="small"
                name='dataInicial'
                allowSubmit
                fullWidth
                onChange={(e) => {
                  setDInicial(e.target.value);
                }}
                value={dInicial}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <div className={classes.containerFinal}>
                <TextFieldSaurus
                  tipo='DATA'
                  variant="outlined"
                  label='Data Final'
                  size="small"
                  name='dataFinal'
                  allowSubmit
                  fullWidth
                  onChange={(e) => {
                    setDFinal(e.target.value)
                  }}
                  value={dFinal}
                />

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.btnPesquisa}
                  fullWidth={true}
                >
                  <ProcurarIcon tipo="BUTTON_PRIMARY" />
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Fade>
    </>
  );
}
