import { Redirect, Route } from "react-router"
import { MenuPrincipalProvider } from "../../../services/providers/menu-principal-provider"
import { SettingsPage } from "../../../views/pages/private/settings-page"
import { ResponsePage } from "../../../views/pages/private/response-page"
import { CadastroPage } from "../../../views/pages/private/cadastro-page"
import { HistoricoPage } from "../../../views/pages/private/historico-page"
import { MainPage } from "../../../views/pages/private/menu-principal/main-page"
import { ResponseProvider } from "../../../services"
import LeitorPage from "../../../views/pages/private/leitor/leitor-page"
import { TicketStorageKeys, useTicketStorage } from "../../../services/use-cases/ticket-storage"
import { UserDadosModel } from "../../../model/api/UserDados"
import { EnumTpLeitor } from "../../../model/enums/enum-tp-leitor"
import { useCallback } from "react"

const RouterMain = () => {

    const { getRegistro } = useTicketStorage();

    const usuario = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel;

    const retonaRotaLeitor = useCallback(() => {
        switch (usuario.tpLeitor) {
            case EnumTpLeitor.LeitorCamera:
                return (
                    <Redirect to='/nova-leitura/camera' />
                )
            case EnumTpLeitor.LeitorTeclado:
                return (
                    <Redirect to='/nova-leitura/fisico' />
                )
            case EnumTpLeitor.LeitorCameraTeclado:
                return (
                    <Redirect to='/nova-leitura/camera' />
                )
        }
    }, [usuario.tpLeitor])

    return (
        <MenuPrincipalProvider>
            <MainPage>
                <ResponseProvider>
                    <Route exact path="/configuracoes" component={SettingsPage} />
                    <Route exact path="/response" component={ResponsePage} />
                    <Route exact path="/cadastro" component={CadastroPage} />
                    <Route exact path="/historico-baixas" component={HistoricoPage} />
                    <Route path={`/nova-leitura`} component={LeitorPage} />
                    <Route path="/">
                    {retonaRotaLeitor()}
                    </Route>
                </ResponseProvider>
            </MainPage>
        </MenuPrincipalProvider>
    )
}

export default RouterMain