import { useConfigStyles } from "./settings-page-styles";
import { Grid } from "@material-ui/core";
import { SettingsHeader } from "./components/settings-header/settings-header";
import { CardConfiguracao } from "../../../components/cards/card-configuracao/card-configuracao";

export const SettingsPage = () => {
    const classes = useConfigStyles();

    return (
        <>
            <Grid className={classes.root}>
                <Grid className={classes.header}>
                    <SettingsHeader />
                </Grid>
                <Grid className={classes.list}>
                    <Grid item xs={12}>
                        <CardConfiguracao />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}