import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupNome = ()=>{
   const nomeYup = useCallback(() => {
        return (
            Yup.string().required('Infome seu nome.')
        )
    }, [])
    return{
        nomeYup,
    }
}
