import { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Fade,
  Grid,
  IconButton,
  Box,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useStyles } from './dialog-saurus-styles';
import { DialogSaurusProps } from './dialog-saurus-props';
import { CircularLoading } from '../../utils/circular-loading';
import { CloseIcon } from '../../icons/close-icon';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

export const DialogSaurus = (props: DialogSaurusProps) => {

  const {
    aberto,
    children,
    icone,
    carregando,
    titulo,
    tamanho,
    zIndex,
    isButtonTitleClose,
    handleClickClose,
    fullScreen,
    TransitionComponent,
    bottomArea,
    subTitulo
  } = props

  const classes = useStyles(props);

  return (
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      fullScreen={fullScreen}
      disableScrollLock
      keepMounted
      disableEscapeKeyDown
      TransitionComponent={TransitionComponent ?? Transition}
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
    >
      <DialogTitle disableTypography>
        {isButtonTitleClose ? (
          <Grid className={classes.containerTitle}>
            <Box>
              <Typography variant="h6" className={classes.title}>
                {titulo}
              </Typography>
              {subTitulo && (
                <Typography variant="body1" className={classes.subTitle}>
                  {subTitulo}
                </Typography>
              )}
            </Box>
            <IconButton
              className={classes.closeButton}
              onClick={handleClickClose}
            >
              <CloseIcon tipo="BUTTON" fill="#666" style={{ width: 30 }} />
            </IconButton>
          </Grid>
        ) : (
          <Grid className={classes.titleCentralizado}>
            {icone ? icone : null}
            <Box>
              <Typography variant="h6" className={classes.title}>
                {titulo}
              </Typography>
              {subTitulo && (
                <Typography variant="body1" className={classes.subTitle}>
                  {subTitulo}
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : children}
      </DialogContent>
      {bottomArea && <div>
        {bottomArea}
      </div>}
    </Dialog>
  );
};
