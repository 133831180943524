import { createStyles, makeStyles } from "@material-ui/core"

export const useResponseListStyles = makeStyles((theme) =>
    createStyles({
        container: {
            padding: '0 33px',
            width: '100%',
            height: '100%',
            marginTop: '40px'
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
        },
        text: {
            color: '#F68D31',
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '25px',
            fontSize: '28px',
            fontWeight: 600,
        },
        footerBox: {
            marginTop: '35px',
            maxWidth: '20px'
        },
        iten: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        lineText: {
            marginTop: '15px',
        },
        textCategoria: {
            fontWeight: 600,
            color: '#5F6368',
            fontSize: '14px',
            textAlign: 'end',
            "& span": {
                color: '#F68D31'
            },
        },
        titleObject: {
            fontWeight: 700,
            fontSize: 15,
            color: '#F68D31'
        },
        valueObject: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#696969'
        },
        setor: {
            backgroundColor: '#FF9F4A',
            padding: '4px 10px',
            fontSize: '.8rem',
            fontWeight: 600,
            color: '#FFF',
            borderRadius: '5px',
        },
        subContainer: {
            padding: '10px 0'
        },
        title: {
            fontWeight: 700,
            color: '#5F6368',
            fontSize: '16px',
            padding: '3px 0 8px'
        },
        divider: {
            width: '100%',
            height: '1px',
            marginTop: '8px',
            marginBottom: '10px',
            backgroundColor: '#E6E6E6',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: '1px',
                marginTop: '8px',
                marginBottom: '10px',
                backgroundColor: '#E6E6E6',
            }
        },
        divider2: {
            width: '100%',
            height: '1px',
            margin: '10px 0',
            backgroundColor: '#696969',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: '12px 0',
                height: '1px',
                backgroundColor: '#696969',
            }
        },
    }));
