import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./leitor-fisico-style";
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useRef, useState } from "react";
import { useResponseContext } from "../../../../../../../../services";
import { useQrCode } from "../../../../../../../../data/api/baixas/qrcode/qrcode-service";
import { useSessaoAtual } from "../../../../../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import useEventListener from "@use-it/event-listener";
import { QrCodeApiModel } from "../../../../../../../../model/api/QrCode/QrCodeApiModel";
import { stringNumeros } from "../../../../../../../../utils/string-numeros";
import { ProdModel } from "../../../../../../../../model/api/Prod";
import { CircularLoading } from "../../../../../../../components/utils/circular-loading";
import { KeyboardString } from "../../../../../../../components/keyboard/keyboard-string/keyboard-string";
import { ConfirmarIcon } from "../../../../../../../components/icons/confirmar-icon";
import { useToastSaurus } from "../../../../../../../../services/use-cases/toast-saurus";
import { LimparIcon } from "../../../../../../../components/icons/limpar-icon";
import { ToastScan } from "../scan-camera/toast-scan/toast-scan";

export const LeitorFisico = () => {

    //AUX
    const classes = useStyles();
    const { showToast, showToastPersonalizado } = useToastSaurus();

    //PROVIDERS
    const { getData } = useSessaoAtual();
    const { setProd } = useResponseContext();

    //CHAMADA DA API
    const { ScanQrCode, carregando } = useQrCode();

    //STATES E REFS
    const [codigo, setCodigo] = useState<string>('')
    const clickButton = useRef<HTMLButtonElement>(null)
    const [digitado, setDigitado] = useState<boolean>(false);

    useEventListener("keydown", (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            clickButton.current?.click()
        }
    })

    const handleScan = useCallback(async (data: any) => {
        if (data) {
            const rplc = data.replaceAll(";", "/")
            const res = await ScanQrCode(
                new QrCodeApiModel(rplc, stringNumeros(String(getData()?.cnpj)),
                    getData()?.nomeTerminal)
            )
            try {
                if (res.erro) {
                    if (res.erro.title === 'Entrega já registrada!') {
                        const model = res.erro.body as ProdModel;
                        setProd(model)
                        showToastPersonalizado(
                            'confirm',
                            <ToastScan
                                erro={res.erro}
                                model={model}
                            />,
                            10000,
                        );
                        return;
                    }

                    throw (res.erro)
                }

                let model = res.resultado?.data as ProdModel;
                setProd(model)
                showToastPersonalizado(
                    'confirm',
                    <ToastScan
                        erro={res.erro}
                        model={model}
                    />,
                    10000,
                );
            } catch (e: any) {
                showToast('error', res.erro.message);
                return;
            }
            finally {
                setCodigo('');
            }
        }
    }, [ScanQrCode, getData, setProd, showToast, showToastPersonalizado])

    useEffect(() => {
        if (codigo === undefined) {
            setCodigo('')
        }
    }, [codigo])

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Container className={classes.defaultCard}>
                <Grid container className={classes.containerRoot}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <div className={classes.cardCenter}>
                                <div>
                                    <Typography
                                        variant="h5"
                                        className={classes.title}
                                    >
                                        Digite ou passe o código no Leitor:
                                    </Typography>
                                    <div className={classes.divider} />
                                </div>
                                <div>
                                    <div className={classes.text}>
                                        <Typography className={classes.subTitle}>
                                            {
                                                codigo
                                                    ? codigo.replaceAll(";", "/")
                                                    : 'Aproxime o leitor físico do código ou digite-o'
                                            }
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} className={classes.containerButton}>
                        <Button
                            size="medium"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => setCodigo('')}
                        >
                            <LimparIcon tipo="BUTTON" />
                            Limpar
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            ref={clickButton}
                            onClick={() => handleScan(codigo)}
                            disabled={!codigo}
                        >
                            <ConfirmarIcon tipo="BUTTON" />
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
                <KeyboardString
                    digitado={digitado}
                    vDigitado={codigo}
                    setvDigitado={setCodigo}
                    setDigitado={setDigitado}
                />
            </Container>
        </>
    )
}