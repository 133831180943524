import { useCallback } from "react";

export enum TicketStorageKeys {
  AuthToken,
  Historico,
  ConfiguracoesMenu,
  UltimoScan,
  UsuarioAtual,
}

export function useTicketStorage() {

  const getRegistro = useCallback(
    (key: TicketStorageKeys, session: boolean = true) =>
      JSON.parse(
        (session
          ? sessionStorage.getItem(TicketStorageKeys[key].toString())
          : localStorage.getItem(TicketStorageKeys[key].toString())) || "{}"
      ),
    []
  );

  const exists = useCallback((key: TicketStorageKeys, session: boolean = true) => {
    if (session) return Boolean(sessionStorage.getItem(TicketStorageKeys[key].toString()));
    else return Boolean(localStorage.getItem(TicketStorageKeys[key].toString()));
  }, []);

  const setRegistro = useCallback((key: TicketStorageKeys, obj: any, session: boolean = true) => {
    if (session) {
      sessionStorage.setItem(TicketStorageKeys[key].toString(), JSON.stringify(obj));
    } else {
      localStorage.setItem(TicketStorageKeys[key].toString(), JSON.stringify(obj));
    }
  }, []);

  const delRegistro = useCallback((key: TicketStorageKeys, session: boolean = true) => {
    if (session) {
      sessionStorage.removeItem(TicketStorageKeys[key].toString());
    } else {
      localStorage.removeItem(TicketStorageKeys[key].toString());
    }
  }, []);

  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists,
  };
}
