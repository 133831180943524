import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const HistoricoIcon = (props: DefaultIconProps) => {

    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M24.9858 4C13.3839 4 4.00006 13.4159 4.00006 25.1145C3.97163 36.8416 13.3839 46.3146 25.0427 46.2861C36.6446 46.2576 46 36.8131 46 25.1145C45.9716 13.4159 36.6161 4 24.9858 4ZM24.9574 44.46C14.2939 44.4314 5.73466 35.7574 5.79153 25.086C5.8484 14.3861 14.4076 5.79759 24.9858 5.82612C35.6493 5.85465 44.2085 14.5287 44.1517 25.2001C44.0948 35.9286 35.564 44.4885 24.9574 44.46Z" />
                <path d="M25.8957 15.8983C25.8957 17.8956 25.8957 19.8929 25.8957 21.8903C25.8957 22.3183 25.981 22.5751 26.4076 22.8319C27.6019 23.5167 28 24.9718 27.4881 26.2558C26.9763 27.4828 25.7251 28.139 24.3602 27.7966C23.9052 27.6825 23.6493 27.7966 23.3365 28.1105C21.8578 29.6227 20.3507 31.135 18.8436 32.6473C18.7299 32.7614 18.6161 32.8755 18.4739 33.0182C18.019 33.4747 17.5071 33.703 16.9953 33.1609C16.5119 32.6473 16.7393 32.1622 17.1659 31.7342C18.4739 30.3931 19.8104 29.0806 21.1185 27.7396C21.346 27.5113 21.5735 27.226 21.8294 27.0262C22.3128 26.6268 22.455 26.2273 22.2844 25.571C22.0284 24.4868 22.4834 23.4881 23.4218 22.9175C23.9905 22.5751 24.0474 22.2327 24.0474 21.6905C24.0474 17.8671 24.0474 14.0437 24.0474 10.2202C24.0474 10.0205 24.0474 9.82075 24.0474 9.62102C24.0758 9.05036 24.3033 8.59383 24.9573 8.59383C25.6398 8.59383 25.8957 9.05036 25.8957 9.64955C25.8957 10.7053 25.8957 11.761 25.8957 12.8167C25.8957 13.8439 25.8957 14.8711 25.8957 15.8983Z" />
            </DefaultIcon>
        </>
    );
};
