import { makeStyles } from "@material-ui/core";

export const useTabStyles = makeStyles((theme) => ({
    tabContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    tabsContainer: {
        flex: '0 0 auto',
        background: 'none',
        boxShadow: 'none',
        "& .Mui-selected": {
        }
    },
    swipeDiv: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    tabContent: {
        flex: '1 1 100%',
        overflow: 'auto',
    }

}));
