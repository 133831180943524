import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { DefaultFormProps, DefaultFormRefs } from '../utils';
import { makeUtilClasses, useThemeQueries } from '../../../theme';
import { Controller, useForm } from 'react-hook-form';
import { Box, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { CircularLoading } from '../../utils/circular-loading';
import { TextFieldSaurus } from '../../controles/inputs';
import { UserDadosModel } from '../../../../model/api/UserDados/user-dados-model';
import { useFormCadastroValidation } from './form-cadastro-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { SaurusRadio } from '../../controles/radio/saurus-radio';
import { EnumTpLeitor } from '../../../../model/enums/enum-tp-leitor';
import { TicketStorageKeys, useTicketStorage } from '../../../../services/use-cases/ticket-storage';
import { isEmpty } from 'lodash';
import { useStyles } from './form-cadastro-styles';

export const FormCadastro = forwardRef<
  DefaultFormRefs<UserDadosModel>,
  DefaultFormProps<UserDadosModel>
>(({ loading, ...props }: DefaultFormProps<UserDadosModel>, ref) => {

  // AUX
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { getRegistro } = useTicketStorage();
  const modelStorage = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel;

  const userDadosStorage = !isEmpty(modelStorage) ? modelStorage : new UserDadosModel();

  //REFS
  const refInput = useRef<HTMLInputElement>(null);
  const [tpLeitor, setTpLeitor] = useState<EnumTpLeitor>(userDadosStorage.tpLeitor);
  const [modelForm, setModelForm] = useState<UserDadosModel>(userDadosStorage)

  //VALIDATION
  const { formCadastroValidation } = useFormCadastroValidation()

  const {
    control,
    formState: { errors, touchedFields },
    setValue,
    handleSubmit,
  } = useForm<UserDadosModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(formCadastroValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = useCallback(async (values: UserDadosModel) => {
    props.onSubmit(values);
  }, [props]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInput.current?.focus();
    },
    fillForm: (model: UserDadosModel) => {
      setModelForm(model)
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInput.current?.focus();
      }, 500);
    },
  }));


  return (
    <>
      <div className={classes.root}>
        {!loading && (
          <Box my={2}>
            <div className={utilClasses.formContainer}>
              {loading && props.showLoading ? (
                <div className={utilClasses.controlLoading}>
                  <CircularLoading tipo="NORMAL" />
                </div>
              ) : null}
              <form onSubmit={handleSubmit(onSubmit)} className={loading ? utilClasses.controlLoading : ''}>
                <Grid container spacing={2} className={classes.container}>
                  <Grid item xs={12}>
                    <Controller
                      name="nomeTerminal"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          variant="outlined"
                          disabled={loading}
                          label="Nome do Terminal"
                          placeholder="Digite o Nome do Terminal"
                          fullWidth
                          error={Boolean(errors.nomeTerminal && errors.nomeTerminal.message)}
                          helperText={
                            touchedFields.nomeTerminal || errors.nomeTerminal
                              ? errors.nomeTerminal?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="cnpj"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          label="CNPJ"
                          autoComplete="off"
                          placeholder="Digite o CNPJ"
                          tipo="CNPJ"
                          fullWidth={true}
                          variant="outlined"
                          error={Boolean(errors.cnpj && errors.cnpj.message)}
                          helperText={
                            touchedFields.cnpj || errors.cnpj
                              ? errors.cnpj?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="senha"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo='PASSWORD'
                          disabled={loading}
                          showPasswordVisibilityButton
                          label="Senha"
                          autoComplete="off"
                          placeholder="Digite sua senha"
                          fullWidth={true}
                          variant="outlined"
                          error={Boolean(errors.senha && errors.senha.message)}
                          helperText={
                            touchedFields.senha || errors.senha
                              ? errors.senha?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="confirmarSenha"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo='PASSWORD'
                          disabled={loading}
                          showPasswordVisibilityButton
                          label="Confirmar Senha"
                          autoComplete="off"
                          placeholder="Digite Novamente sua senha"
                          fullWidth={true}
                          variant="outlined"
                          allowSubmit
                          error={Boolean(errors.confirmarSenha && errors.confirmarSenha.message)}
                          helperText={
                            touchedFields.confirmarSenha || errors.confirmarSenha
                              ? errors.confirmarSenha?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      color='primary'
                    >
                      Modelo de Operação
                    </Typography>
                    <Divider variant='fullWidth' className={classes.divider} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      label='Leitor Por Câmera'
                      className={classes.titleOpcoes}

                      onClick={() => {
                        setTpLeitor(EnumTpLeitor.LeitorCamera)
                        setValue('tpLeitor', EnumTpLeitor.LeitorCamera)
                      }}
                      checked={tpLeitor === EnumTpLeitor.LeitorCamera}
                      control={<SaurusRadio />}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      label='Leitor Por Teclado (USB/Bluetooth)'
                      className={classes.titleOpcoes}
                      onClick={() => {
                        setTpLeitor(EnumTpLeitor.LeitorTeclado)
                        setValue('tpLeitor', EnumTpLeitor.LeitorTeclado)
                      }}
                      checked={tpLeitor === EnumTpLeitor.LeitorTeclado}
                      control={<SaurusRadio />}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      label='Leitor Por Câmera e Teclado'
                      className={classes.titleOpcoes}
                      onClick={() => {
                        setTpLeitor(EnumTpLeitor.LeitorCameraTeclado)
                        setValue('tpLeitor', EnumTpLeitor.LeitorCameraTeclado)
                      }}
                      checked={tpLeitor === EnumTpLeitor.LeitorCameraTeclado}
                      control={<SaurusRadio />}
                    />
                  </Grid>
                </Grid>
                <button type="submit" style={{ display: 'none' }} />
              </form>
            </div>
          </Box>
        )}
      </div>
    </>
  );
});
