import { Grid, Typography } from "@material-ui/core"
import { isEmpty } from "lodash"
import { PrivatePageHeaderProps } from "./header-private-page-props"
import { useStyles } from "./header-private-page-styles"

export const PrivatePageHeader = (props: PrivatePageHeaderProps) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.mainHeaderContainer}>
            <Grid item xs={12} >
                <Grid container className={classes.topContainer} direction="row" justifyContent="space-between" spacing={1} alignItems="center">
                    <Grid item className={classes.flexContent}>
                        <Grid container spacing={1} alignItems="center">
                            {
                                props.leftArea &&
                                (<Grid item className={classes.flexFixed}>
                                    {props.leftArea}
                                </Grid>)
                            }
                            {
                                props.middleArea &&
                                (<Grid item className={classes.flexContent}>
                                    {props.middleArea}
                                </Grid>)
                            }
                            {
                                isEmpty(props.middleArea) &&
                                (<Grid item className={classes.flexContent}>
                                    <Typography variant="h4" className={classes.title}>
                                        {props.title}
                                    </Typography>
                                </Grid>)
                            }
                        </Grid>
                    </Grid>
                    {
                        props.rightArea &&
                        (<Grid item className={classes.flexFixed}>
                            {props.rightArea}
                        </Grid>)
                    }
                </Grid>
                {
                    props.bottomArea &&
                    (<Grid className={classes.bottomContainer} item xs={12}>
                        {props.bottomArea}
                    </Grid>)
                }
            </Grid>
        </Grid>
    )
}
