import useEventListener from '@use-it/event-listener';
import { useKeyDownHandler } from '../../../../services/use-cases/keydown-handler';
import { focusableElement } from '../../../../utils/focusable-element';
import { KeyboardProps } from './keyboard-string-props';

export const KeyboardString = ({
  isButtonAddTopKeyboard,
  setDigitado,
  setvDigitado,
  vDigitado,
  digitado,
}: KeyboardProps) => {

  const { handleKeyDown } = useKeyDownHandler();

  useEventListener('keydown', (event: any) => {
    if (!event.defaultPrevented && !focusableElement(document.activeElement)) {
      const ev = event as KeyboardEvent;

      const newValue = handleKeyDown(ev, vDigitado, true, true, true, 0, 1000);

      let valor = vDigitado;

      setDigitado(true);
      setvDigitado(valor + vDigitado);

      setvDigitado(newValue);

      if (newValue !== vDigitado) {
        ev.stopPropagation();
        ev.preventDefault();
      }
    }
  });

  return (
    <></>
  );
};
