import { makeStyles, Theme } from "@material-ui/core";
import { MenuProviderModelo } from "../../../../../../../services/providers/menu-principal-provider/menu-principal-provider-types";

interface MenuPrincipalTopSylesProps {
  modeloAtual: MenuProviderModelo;
}

export const useStyles = makeStyles<Theme, MenuPrincipalTopSylesProps>((theme) => ({
  pinButton: {
    width: '34px'
  },
  pinButtonIcon: {
    height: '18px',
    width: '18px',
    transform: "rotate(0deg)",
    "-webkit-transition": "-webkit-transform .4s ease-in-out",
    "-ms-transition": "-ms-transform .4s ease-in-out",
    transition: "transform .4s ease-in-out",
  },
  pinButtonMini: {
    transform: "rotate(180deg)"
  },
  drawerDefaultTopButtonsContainer: ({ modeloAtual }) => ({
    display: "flex",
    justifyContent: modeloAtual ==='Mini' ? 'center' : 'flex-end',
    alignItems: "center",
    marginBottom: "-10px",
  }),
}));
