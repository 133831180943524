import { makeStyles, } from "@material-ui/core";

export const useConfiguracaoStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        height: '100%',
        maxWidth: '690px',
        padding: '15px 35px 35px 35px',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 41px 110px rgba(0, 0, 0, 0.05)',
        borderRadius: '20px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            margin: '0 20px',
            padding: '15px 30px 30px 30px',
            background: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0px 41.9453px 109.058px rgba(0, 0, 0, 0.05)',
            borderRadius: '21px',
        }
    },
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden"
    },
    header: {
        flex: "0 1 auto"
    },
    rootContainer: {
        position: "relative",
        maxWidth: "850px",
    },
    contentCenter: {
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2, 0),
        justifyContent: 'center',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        fontWeight: 700,
    },
    content: {
        maxWidth: '650px',
    },
    toast: {
        width: '100%',
        padding: '25px',
        marginTop: '10px',
        textAlign: 'center'
    },
    back: {
        position: 'absolute',
        marginToi: '10px',
    },
    backIcon: {
        color: '#1f9ce4',
        fontSize: '60px'
    },
    buttonWrapper: {
        padding: '35px',
    },
    button: {
        backgroundColor: '#1f9ce4',
        marginTop: '25px',
        textTransform: 'capitalize',
        color: 'white',
        fontWeight: 700,
        boxShadow: '0.964912px 2.82121px 5.78947px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        "&:hover": {
            backgroundColor: '#1f9ce4'
        }
    },
    text: {
        padding: '35px',
        color: '#8D8D8D',
        paddingTop: '0px',
        paddingBottom: '10px',
        marginBottom: '30px',
        fontSize: '18px',
        textAlign: 'justify',
        fontWeight: 500,
        lineHeight: '120%'
    },
    textField: {
        backgroundColor: '#F0F0F0!important'
    },
    input: {
        padding: '32px',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    startIcon: {
        color: '#1f9ce4',
        fontSize: '25px',
        fontWeight: 700,
        padding: '7px'
    },
    box: {
        marginTop: '60px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '35px'
        }
    },
    field: {
        color: '#000',
    },
    divider: {
        background: theme.palette.grey[300]
    },
    dividerTitle: {
        width: '100%',
        height: '2px',
        marginTop: '3px',
        marginBottom: '30px',
        backgroundColor: '#E6E6E6',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '2px',
            marginTop: '3px',
            marginBottom: '30px',
            backgroundColor: '#E6E6E6'
        }
    },
})
)