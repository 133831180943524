import { Grid } from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import { FormConfirmacaoUsuarioModel } from "../../../../model/app/form/form-confirmacao-usuario-model"
import { forwardRef, useCallback, useImperativeHandle } from "react"
import { DefaultFormProps, DefaultFormRefs } from "../utils"
import { TextFieldSaurus } from "../../controles/inputs"
import { useFormConfirmacaoUsuarioValidation } from "./form-confirmacao-usuario-validation"
import { yupResolver } from "@hookform/resolvers/yup"

export const FormConfirmacaoUsuario = forwardRef<
    DefaultFormRefs<FormConfirmacaoUsuarioModel>,
    DefaultFormProps<FormConfirmacaoUsuarioModel>
>(({ loading, ...props }: DefaultFormProps<FormConfirmacaoUsuarioModel>, ref) => {

    const { formConfirmacaoUsuarioValidation } = useFormConfirmacaoUsuarioValidation();

    const {
        formState: { errors, touchedFields },
        control,
        handleSubmit,
    } = useForm<FormConfirmacaoUsuarioModel>({
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(formConfirmacaoUsuarioValidation),
    })

    const onSubmit = useCallback((model: FormConfirmacaoUsuarioModel) => {
        props.onSubmit(model);
    }, [props])

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: () => {
        },
    }));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item xs={12}>
                    <Controller
                        name="senha"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                label='Senha'
                                tipo="PASSWORD"
                                placeholder="Digite sua senha sync"
                                size="medium"
                                disabled={props.showLoading}
                                showPasswordVisibilityButton
                                allowSubmit
                                error={Boolean(errors.senha && errors.senha.message)}
                                helperText={
                                    touchedFields.senha || errors.senha
                                        ? errors.senha?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </form>
    )
});