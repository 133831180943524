import { Grid, Paper, Slide, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useResponseContext } from "../../../../../../services";
import { formatarCPFCNPJ } from "../../../../../../utils/cpfcnpj-format";
import { formatData } from "../../../../../../utils/format-data";
import { formatDataHora } from "../../../../../../utils/format-data-hora";
import { useResponseListStyles } from "./response-list-styles";

export const ResponseList = () => {
  const classes = useResponseListStyles();
  const { prod } = useResponseContext();

  return (
    <>
      {!isEmpty(prod?.id ) ?
        <>
          <Grid item xs={12} md={12} style={{ marginBottom: "16px" }}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
              <Paper elevation={1}>
                <Grid container style={{ padding: '15px 20px' }}>
                  <Grid item xs={6} md={6}>
                    <Typography variant="body1">
                      <span style={{ color: '#F68D31', fontWeight: 500, fontSize: 15 }}>Código:</span> {prod?.codigo}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography style={{ textAlign: 'right' }} className={classes.textCategoria}>
                      <span style={{ color: '#F68D31', fontWeight: 500, fontSize: 15 }}>Seq:</span> {prod?.nroItens}
                    </Typography>
                  </Grid>
                  <div className={classes.divider} />
                  <Grid item xs={12} >
                    <Typography variant="body2" className={classes.titleObject}>
                      Produto
                    </Typography>
                    <Typography className={classes.title}>
                      {prod?.nome}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} style={{ padding: '0 0 14px 0' }}>
                    <Typography variant="body2" className={classes.titleObject}>
                      Cupom
                    </Typography>
                    <Typography className={classes.valueObject}>
                      {prod?.nroCupom} - {prod?.tpCupom}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'center' }}>
                    <Typography variant="body2" className={classes.titleObject}>
                      Qtd total
                    </Typography>
                    <Typography className={classes.valueObject}>
                      {prod?.quantidade}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Typography variant="body2" className={classes.titleObject}>
                      Valor nota
                    </Typography>
                    <Typography className={classes.valueObject} style={{ padding: '0 8px' }}>
                      {prod?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Slide>
          </Grid>
          <Grid item xs={12}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
              <Paper elevation={1} style={{ padding: '15px 20px' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <span className={classes.setor}>
                      {prod?.terminal}
                    </span>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="body1" className={classes.textCategoria}>
                      <span>Funcionário:</span> {prod?.usuario}
                    </Typography>
                  </Grid>
                  <div className={classes.divider2} />
                  <Grid item xs={4} className={classes.subContainer}>
                    <Typography variant="body2" className={classes.titleObject}>
                      CNPJ
                    </Typography>
                    <Typography className={classes.valueObject}>
                      {formatarCPFCNPJ(prod?.cnpj)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.subContainer} style={{ textAlign: 'center' }}>
                    <Typography variant="body2" className={classes.titleObject}>
                      Emissão
                    </Typography>
                    <Typography className={classes.valueObject}>
                      {formatData(new Date(prod.data))}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.subContainer} style={{ textAlign: 'right' }}>
                    <Typography variant="body2" className={classes.titleObject}>
                      Leitura/Baixa
                    </Typography>
                    <Typography className={classes.valueObject}>
                      {formatDataHora(new Date(prod?.dataLeitura))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <Typography variant="body2" className={classes.titleObject}>
                      ID
                    </Typography>
                    <Typography className={classes.valueObject}>
                      {prod?.id}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Slide>
          </Grid>
        </>
        :
        ''
      }
    </>
  );
}
