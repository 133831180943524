import { makeStyles } from "@material-ui/core"


export const useStyles = makeStyles((theme) => ({
    defaultCard: {
        padding: '8px',
        cursor: 'default',
        height: '100%'
    },
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    cardCenter: {
        [theme.breakpoints.down('xs')]: {
            width: '390px',
            height: '500px',
            display: 'flex',
            padding: '30px 15px 15px 15px',
            margin: '10px 0',
            background: theme.palette.background.paper,
            boxShadow: '0px 41.9453px 109px rgba(0, 0, 0, 0.05)',
            borderRadius: theme.shape.borderRadius
        }
    },
    container: {
        display: 'flex',
        padding: '0 1rem',
        alignItems: 'center',
        justifyContent: 'center'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    title: {
        fontSize: '25px',
        color: '#3D3D3D',
        textAlign: 'center',
        fontWeight: 700,
    },
    titleSub: {
        fontSize: '18px',
        color: '#3D3D3D',
        textAlign: 'center',
        fontWeight: 700,

    },
    qrcode: {
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        background: theme.palette.primary.main, 
        marginBottom: '8px'
    }
}))