type Color = "success" | "warning" | "error" | "info"

export const colorLog = (color: Color) => {
    let cor = ''

    switch (color) {
        case "success":
            cor = '#10C822'
            break;
        case "warning":
            cor = '#FFBC00'
            break;
        case "error":
            cor = '#F44336'
            break;
        case "info":
            cor = "#1F9CE4"
            break;
        default:
            cor = "#1F9CE4"
    }

    return `color: ${cor}`
}