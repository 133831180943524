import { createStyles, makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) =>
    createStyles({
        cardLogin: {
            width: '100%',
            height: '500px',
            maxWidth: '620px',
            display: 'flex',
            borderRadius: '29px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 30px',
            boxShadow: ' 0px 59.2428px 154.031px rgba(0, 0, 0, 0.1)',
            background: '#FFFFFF',
            [theme.breakpoints.down('xs')]: {
                width: '378px',
                height: '492px',
                background: 'rgba(255, 255, 255, 0.9)',
                boxShadow: '0px 41.9453px 109.058px rgba(0, 0, 0, 0.05)',
                borderRadius: '21px',
                padding: '50px 9px',
            }
        },
        title: {
            color: theme.palette.primary.main,
            fontSize: '30px',
            fontWeight: 700,
            margin: '0 0 50px 0'
        },
        text: {
            color: theme.palette.text.secondary,
            fontSize: '18px',
            lineHeight: '150%',
            fontWeight: 600,
            margin: '27px 0'
        },
        divider: {
            width: '100%',
            height: '2px',
            marginTop: '50px',
            marginBottom: '50px',
            backgroundColor: '#E6E6E6',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: '2px',
                marginTop: '50px',
                marginBottom: '10px',
                backgroundColor: '#E6E6E6',
            }
        },
        button: {
            "&:hover": {
                backgroundColor: '#f68d31db'
            }
        }
    })
)