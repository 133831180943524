import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMenuPrincipal } from "../../../../../../../../services/providers/menu-principal-provider";
import { useThemeQueries } from "../../../../../../../theme";

export const useAcoesMenu = () => {
  const history = useHistory();
  const { isMobile } = useThemeQueries();
  const { fecharMenu } = useMenuPrincipal();

  const selectAction = useCallback(
    (codigoModulo: number, path: string) => {
      switch (codigoModulo) {
        default:
          history.push(path);
          break;
      }
      if (isMobile) {
        fecharMenu();
      }
    },
    [isMobile, history, fecharMenu]
  );
  return { selectAction: selectAction };
};
