import {
    BrowserRouter,
    Route,
    Switch,
} from "react-router-dom";
import { LoginPage } from '../views/pages/public/login-page'
import { useMemo } from "react";
import LogoutPage from "../views/pages/public/logout/logout-page";
import { SessaoAtualProvider } from "../services/providers/sessao-atual-provider/sessao-atual-provider";
import { GlobalStatesProvider } from "../services/providers/global-states-provider/global-states-provider";
import { RecoilRoot } from "recoil";
import { FirstAccessPage } from "../views/pages/public/first-access-page";
import { CadastroPage } from "../views/pages/private/cadastro-page";
import RouterMain from "./components/router-main/router-main";
import { ExpiredTokenRoute } from "./components/expired-token/expired-token";

export const RouterApp = () => {
    const switchApp = useMemo(() => (
        <>
            <Switch>
                <Route exact path="/logout" component={LogoutPage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/cadastro" component={CadastroPage} />
                <Route exact path="/primeiro-acesso" component={FirstAccessPage} />
                <ExpiredTokenRoute>
                    <RouterMain />
                </ExpiredTokenRoute>
            </Switch>
        </>
    ), []);
    return (
        <SessaoAtualProvider>
            <GlobalStatesProvider>
                <RecoilRoot>
                    <BrowserRouter>
                        {switchApp}
                    </BrowserRouter>
                </RecoilRoot>
            </GlobalStatesProvider>
        </SessaoAtualProvider>
    );
};