import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from "../../../../../components/tabs/tabs-saurus";
import { CircularLoading } from "../../../../../components/utils/circular-loading";
import { useStyles } from "./leitor-tabs-styles";
import { LeitorFisico } from "./components/leitor-fisico/leitor-fisico";
import { ScanCamera } from "./components/scan-camera/scan-camera";
import { TicketStorageKeys, useTicketStorage } from "../../../../../../services/use-cases/ticket-storage";
import { UserDadosModel } from "../../../../../../model/api/UserDados";
import { EnumTpLeitor } from "../../../../../../model/enums/enum-tp-leitor";

export const LeitorTabs = () => {

   //ROUTES
   const rotaScanCamera = "/nova-leitura/camera";
   const rotaScanFisico = "/nova-leitura/fisico";

   //AUX
   const classes = useStyles();
   const { replace, location } = useHistory();
   const { getRegistro } = useTicketStorage();

   const usuarioAtual = getRegistro(TicketStorageKeys.UsuarioAtual, false) as UserDadosModel;

   //STATES
   const [carregandoInicial, setCarregandoInicial] = useState(false);

   const retornaAba = (path: string) => {
      return usuarioAtual.tpLeitor === EnumTpLeitor.LeitorTeclado
         ? 0
         : path.includes(rotaScanCamera)
            ? 0
            : 1
   }

   const [abaSelecionada, setAbaSelecionada] = useState(retornaAba(location.pathname));

   const tabChange = (index: number) => {
      if (index === 0) {
         replace(rotaScanCamera);
      }
      else {
         replace(rotaScanFisico);
      }
   };

   useEffect(() => {
      const aba = retornaAba(location.pathname);
      setAbaSelecionada(aba);
      setCarregandoInicial(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location.pathname])

   const switchTabsLabel = useCallback(() => {
      switch (usuarioAtual.tpLeitor) {
         case EnumTpLeitor.LeitorCamera:
            return [
               new TabSaurusLabel('Câmera', 0),
            ]
         case EnumTpLeitor.LeitorTeclado:
            return [
               new TabSaurusLabel('Leitor Físico', 0),
            ]
         case EnumTpLeitor.LeitorCameraTeclado:
            return [
               new TabSaurusLabel('Câmera', 0),
               new TabSaurusLabel('Leitor Físico', 1),
            ]
      }
   }, [usuarioAtual.tpLeitor])

   const switchTabsContent = useCallback(() => {
      switch (usuarioAtual.tpLeitor) {
         case EnumTpLeitor.LeitorCamera:
            return [
               new TabSaurusContent(0,
                  <>
                     <div className={classes.defaultContainer}>
                        {carregandoInicial && <CircularLoading tipo="FULLSIZED" />}
                        <Grid container>
                           <Grid item xs={12} className={classes.listContainer}>
                              <ScanCamera />
                           </Grid>
                        </Grid>
                     </div>
                  </>
               )
            ];
         case EnumTpLeitor.LeitorTeclado:
            return [
               new TabSaurusContent(0,
                  <>
                     <div className={classes.defaultContainer}>
                        {carregandoInicial && <CircularLoading tipo="FULLSIZED" />}
                        <Grid container>
                           <Grid item xs={12} className={classes.listContainer}>
                              <LeitorFisico />
                           </Grid>
                        </Grid>
                     </div>
                  </>
               )
            ];
         case EnumTpLeitor.LeitorCameraTeclado:
            return [
               new TabSaurusContent(0,
                  <>
                     <div className={classes.defaultContainer}>
                        {carregandoInicial && <CircularLoading tipo="FULLSIZED" />}
                        <Grid container>
                           <Grid item xs={12} className={classes.listContainer}>
                              <ScanCamera />
                           </Grid>
                        </Grid>
                     </div>
                  </>
               ),
               new TabSaurusContent(1,
                  <>
                     <div className={classes.defaultContainer}>
                        {carregandoInicial && <CircularLoading tipo="FULLSIZED" />}
                        <Grid container>
                           <Grid item xs={12} className={classes.listContainer}>
                              <LeitorFisico />
                           </Grid>
                        </Grid>
                     </div>
                  </>
               )
            ];
      }
   }, [carregandoInicial, classes.defaultContainer, classes.listContainer, usuarioAtual.tpLeitor])

   return (
      <>
         <TabsSaurus
            selectedTabIndex={abaSelecionada}
            onChange={tabChange}
            tabsLabel={switchTabsLabel()}
            tabsContent={switchTabsContent()}
         />
      </>
   );
};
