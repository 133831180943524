import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupSenha = () => {
    const senhaYup = useCallback((required: boolean = true) => {
        return (
            required
                ? Yup.string().required('A senha é obrigatória.').min(6, 'Senha inválida. (6-32 caracteres)').max(32, 'Senha inválida. (6-32 caracteres)')
                : Yup.string().test('UmTeste', 'Senha inválidaaaa. (6-32 caracteres)', val => {
                    if (isEmpty(val)) {
                        return true
                    }
                    if (val) {
                        if (val.length >= 6 && val.length <= 32) return true
                    }

                    return false
                })
        )
    }, [])

    const senhaSyncYup = useCallback(() => {
        return (
            Yup.string().required('A senha é obrigatória.').min(5, 'Senha inválida. (6-32 caracteres)').max(32, 'Senha inválida. (6-32 caracteres)')
        )
    }, [])

    return {
        senhaYup,
        senhaSyncYup
    }
}