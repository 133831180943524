import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    defaultCard: {
        boxShadow: theme.shadows[1],
        background: '#FFF',
        borderRadius: '5px',
        cursor: 'default',
        display: 'flex',
        alignItems: 'center'
    },
    containerRoot: {
        padding: '8px'
    },
    root: {

        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardCenter: {
        background: '#FFF',
        overflowX: 'hidden',
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        }
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerCod: {
        backgroundColor: '#EBEBEB',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#EBEBEB',
            width: '100%',
        }
    },
    title: {
        fontSize: '25px',
        color: '#5F6368',
        textAlign: 'center',
        fontWeight: 700,
        padding: '15px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '25px',
            color: '#5F6368',
            textAlign: 'center',
            fontWeight: 700,
        }
    },
    subTitle: {
        color: '#808080',
        fontSize: '17px',
        fontWeight: 600,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            color: '#808080',
            fontSize: '17px',
            fontWeight: 700,
        }
    },

    qrCode: {
        wordBreak: 'break-all',
        padding: '17px',
        textAlign: 'center'
    },
    divider: {
        width: '100%',
        height: '2px',
        backgroundColor: '#F68D31',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '2px',
            backgroundColor: '#F68D31',
        }
    },
    containerButton: {
        paddingRight: '8px'
    },
    text: {
        wordBreak: 'break-all',
        padding: '16px',
        textAlign: 'center'
    }
}))