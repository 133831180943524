import { Typography, Grid, Button, Collapse, Divider, Box } from '@material-ui/core';
import { HistoricoCardProps } from './historico-card-props';
import { useHistoricoStyles } from './historico-card-styles'
import { useState } from 'react';
import { AvancarIcon } from '../../../../../components/icons/avancar-icon';
import { formatDataHora } from '../../../../../../utils/format-data-hora';
import { DefaultCard, useDefaultCardStyles } from '../../../../../components/cards/components';
import { toDecimalString } from '../../../../../../utils/to-decimal';
import { formatarCPFCNPJ } from '../../../../../../utils/cpfcnpj-format';
import classNames from 'classnames';

export const HistoricoCard = ({
  model,
}: HistoricoCardProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const classes = useHistoricoStyles({
    open
  });

  const classesCard = useDefaultCardStyles();

  return (
    <>
      <DefaultCard>
        <Grid container spacing={1} className={classes.containerRoot}>
          <Grid item xs={6} className={classes.containerHeader}>
            <div className={classes.containerTerminal}>
              <Typography
                variant='body2'
                className={classes.textoTerminal}
              >
                <Box fontWeight={500}>
                  {model.terminal}
                </Box>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.containerEmissao}>
            <div>
              <Typography
                variant='caption'
                color='textPrimary'
              >
                Data Emissão
              </Typography>
              <Typography
                variant='body2'
                color='textPrimary'
              >
                {formatDataHora(model.data)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider variant='fullWidth' className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='caption'
              color='textPrimary'
            >
              Produto
            </Typography>
            <Typography
              variant='body2'
              color='textPrimary'
              style={{
                whiteSpace: 'normal'
              }}
            >
              <Box fontWeight={600}>
                {model.nome}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classesCard.cardContent}>
              <div className={classNames(classesCard.celulaGrid, classes.cupomContainer)}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Cupom
                </Typography>
                <Typography
                  variant='body2'
                  color='textPrimary'
                >
                  {model.nroCupom} - {model.tpCupom}
                </Typography>
              </div>
              <div className={classesCard.celulaGrid}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Qtd Total
                </Typography>
                <Typography
                  variant='body2'
                  color='textPrimary'
                >
                  {model.quantidade}UN
                </Typography>
              </div>
              <div className={classesCard.celulaGrid}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Qtd Total
                </Typography>
                <Typography
                  variant='body2'
                  color='textPrimary'
                >
                  {model.nroItens}
                </Typography>
              </div>
              <div className={classesCard.celulaGrid}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Valor Nota
                </Typography>
                <Typography
                  variant='body2'
                  color='textPrimary'
                >
                  {`R$ ${toDecimalString(model.valor)}`}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider variant='fullWidth' className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                  >
                    ID
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textPrimary'
                  >
                    {model.id}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                  >
                    Código
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textPrimary'
                  >
                    {model.codigo}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                  >
                    CNPJ
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textPrimary'
                  >
                    {formatarCPFCNPJ(model.cnpj)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                  >
                    Funcionário
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textPrimary'
                  >
                    {model.usuario}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                  >
                    Leitura/Baixa
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textPrimary'
                  >
                    {formatDataHora(model.dataLeitura)}
                  </Typography>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid xs={12}>
            <Button
              variant='outlined'
              fullWidth
              onClick={() => setOpen(prev => !prev)}
              className={classes.button}
            >
              <AvancarIcon
                tipo="BUTTON"
              />
              {open ? 'Menos informações' : 'Mais informações'}
            </Button>
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
