import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainHeaderContainer: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    position: "relative",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      background: 'transparent',
      color: theme.palette.text.primary,
      boxShadow: 'none',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[300],
    },
  },
  mainHeaderBackground: {
    position: 'fixed',
    zIndex: -1,
    top: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    minHeight: "65px",
    width: "100%",
    margin: 0,

    [theme.breakpoints.up("md")]: {
      minHeight: "70px",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  bottomContainer: {
    zIndex: 1,
  },
  title: {
    fontWeight: 600,
    textShadow: "2px 2px 1px rgba(0,0,0,0.05)",
    fontSize: "2rem",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
      fontWeight: 400,
    },
  },
  flexContent: {
    flex: 1,
    overflow: 'hidden',
  },
  flexFixed: {
    flex: 0,
  },
  publicPageHeaderTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    "& svg:nth-child(2)": {
      marginTop: '20px',
    },
  },
  publicPageHeaderRightContainer: {
    position: 'fixed',
    zIndex: -1,
    right: 0,
    top: '50%',
    transform: `translateY(-50%)`,

  },
  publicPageHeaderBottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }
}));
