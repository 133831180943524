import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";

import { Grid } from "@material-ui/core";
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { useSessaoAtual } from "../../../../services/providers/sessao-atual-provider/sessao-atual-provider";
import { useToastSaurus } from "../../../../services/use-cases/toast-saurus";
import { PublicPageHeader } from "../../../components/headers/header-public-page";
import { CircularLoading } from "../../../components/utils/circular-loading";


const LogoutPage = () => {
    const { replace: historyReplace } = useHistory();
    const { Logout } = useSessaoAtual();
    const utilClasses = makeUtilClasses();
    const { showToast } = useToastSaurus();

    const efetuarLogout = useCallback(() => {
        try {
            Logout();
        } catch (e: any) {
            showToast("error", e.message);
        }
        historyReplace("/login");
    }, [Logout, historyReplace, showToast]);

    useEffect(() => {
        efetuarLogout();
    }, [efetuarLogout]);

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer}>
                    <CircularLoading tipo="FULLSIZED" />
                </Grid>
            </div>
        </>
    )
}

export default LogoutPage;


